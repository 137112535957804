import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
  selector: 'jofi-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['pagination.component.scss'],
})
export class PaginationComponent implements OnDestroy {
  @Output() pageSelected: EventEmitter<number> = new EventEmitter<number>();
  @Input() currentPage: number | undefined;
  @Input() totalPages: number | undefined;
  public pages: number[] = [];

  constructor() {
  }

  ngOnInit() {
    if (this.totalPages !== undefined && this.totalPages > -1) {
      for (let i = 1; i <= this.totalPages; i++) {
        this.pages.push(i);
      }
    }
  }

  ngOnDestroy(): void {
  }

  public goToPage(page: number) {
    this.currentPage = page;
    this.pageSelected.emit(page);
  }

  public goToNextPage() {
    if (this.currentPage !== undefined) {
      this.currentPage = this.currentPage + 1;
      this.goToPage(this.currentPage);
    }
  }

  public goToPreviousPage() {
    if (this.currentPage !== undefined) {
      this.currentPage = this.currentPage - 1;
      this.goToPage(this.currentPage);
    }
  }
}
