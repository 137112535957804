import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { LoginPage } from './pages/login/login.page';
import { RedirectIfLoggedInGuard } from './guards/redirect-if-logged-in.guard';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { IfLoggedInGuard } from './guards/if-logged-in.guard';
import { UsersPage } from './pages/users/users.page';
import { CompaniesPage } from './pages/companies/companies.page';
import { CompaniesDetailPage } from './pages/companies-detail/companies-detail.page';
import { UsersDetailPage } from './pages/users-detail/users-detail.page';
import { TodosPage } from './pages/todos/todos.page';
import { InterestsPage } from './pages/interests/interests.page';
import { ProfessionsPage } from './pages/professions/professions.page';
import { ProfessionsNewPage } from './pages/professions-new/professions-new.page';
import { PartnersPage } from './pages/partners/partners.page';
import { PartnersNewPage } from './pages/partners-new/partners-new.page';
import { InterestsNewPage } from './pages/interests-new/interests-new.page';
import { StudentProfilesPublicPage } from './pages/student-profiles-public/student-profiles-public.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardPage,
    canActivate: [IfLoggedInGuard],
  },
  {
    path: 'todos',
    component: TodosPage,
    canActivate: [IfLoggedInGuard],
  },
  {
    path: 'interests',
    canActivate: [IfLoggedInGuard],
    children: [
      {
        path: '',
        component: InterestsPage,
      },
      {
        path: 'new',
        component: InterestsNewPage,
      },
    ],
  },
  {
    path: 'partners',
    canActivate: [IfLoggedInGuard],
    children: [
      {
        path: '',
        component: PartnersPage,
      },
      {
        path: 'new',
        component: PartnersNewPage,
      },
    ],
  },
  {
    path: 'professions',
    canActivate: [IfLoggedInGuard],
    children: [
      {
        path: '',
        component: ProfessionsPage,
      },
      {
        path: 'new',
        component: ProfessionsNewPage,
      },
    ],
  },
  {
    path: 'users',
    canActivate: [IfLoggedInGuard],
    children: [
      {
        path: '',
        component: UsersPage,
      },
      {
        path: ':id',
        component: UsersDetailPage,
      },
    ],
  },
  {
    path: 'student-profiles-public',
    canActivate: [IfLoggedInGuard],
    children: [
      {
        path: '',
        component: StudentProfilesPublicPage,
      },
    ],
  },
  {
    path: 'companies',
    canActivate: [IfLoggedInGuard],
    children: [
      {
        path: '',
        component: CompaniesPage,
      },
      {
        path: ':id',
        component: CompaniesDetailPage,
      },
    ],
  },
  {
    path: 'login',
    component: LoginPage,
    canActivate: [RedirectIfLoggedInGuard],
  },
  {
    path: '404',
    component: NotFoundPage,
  },
  {
    path: '**',
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
