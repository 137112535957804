import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { LoggerService } from './logger/logger.service';

@Injectable()
export class ApiJsonInterceptor implements HttpInterceptor {
  constructor() {
  }

  /**
   * Intercept every HTTP request and to the following:
   * - add Bearer token for authentication
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf(environment.apiConfiguration.baseUrl) > -1) {
      return next.handle(req).pipe(
        tap(httpResponse => {
          if (httpResponse instanceof HttpResponse) {
            try {
              LoggerService.LOG(this, req.url.substring(environment.apiConfiguration.baseUrl.length), JSON.parse(httpResponse.body));
            } catch (jsonParseException) {
              LoggerService.LOG(this, req.url.substring(environment.apiConfiguration.baseUrl.length), httpResponse.body);
            }
          }
          return httpResponse;
        }),
      );
    }
    return next.handle(req);
  }
}
