<div>
  <h1 class="text-2xl mb-4">Benutzer:innen</h1>

  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <div class="flex items-center justify-between p-4">
      <label for="table-search" class="sr-only">Suche</label>
      <div class="flex">
        <div class="relative mr-2">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor"
                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"></path>
            </svg>
          </div>
          <input type="text" id="table-search"
                 [(ngModel)]="searchKeyWord"
                 (keyup.enter)="search()"
                 class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                 placeholder="Suche">
        </div>
        <button type="button"
                (click)="search()"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm p-2.5 text-center inline-flex items-center mr-2">
          <div class="w-4 h-4">
            <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
            </svg>
          </div>
          <span class="sr-only">Suche</span>
        </button>
        <button type="button"
                (click)="reset()"
                class="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800">
          <div class="w-4 h-4">
            <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </div>
          <span class="sr-only">Suche zurücksetzen</span>
        </button>
      </div>

      <!-- Sort -->
      <div>
        <button
          (click)="toggleSortByMenu()"
          class="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
          type="button">
          <div class="w-4 h-4 mr-2">
            <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"></path>
            </svg>
          </div>
          {{ 'global.personal.' + currentSortBy.property | translate }} {{ 'global.ui.sortDirection.' + currentSortBy.direction | translate }}
          <svg class="w-3 h-3 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
        <!-- Dropdown menu -->
        <div id="dropdownRadio"
             [class.hidden]="!isSortByMenuOpen"
             class="z-10 w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
             style="position: absolute; top: 60px; right: 0;">
          <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownRadioButton">
            <li *ngFor="let sortByOption of sortByOptions">
              <div class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                   (click)="setCurrentSortBy(sortByOption)">
                <input [id]="sortByOption.property + sortByOption.direction" type="radio" value="" name="filter-radio"
                       class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label [for]="sortByOption.property + sortByOption.direction"
                       class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                  {{ 'global.personal.' + sortByOption.property | translate }} {{ 'global.ui.sortDirection.' + sortByOption.direction | translate }}
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 table-fixed">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" class="px-6 py-3" colspan="2">
          Name
        </th>
        <th scope="col" class="px-6 py-3" colspan="2">
          E-Mail/Benutzername
        </th>
        <th scope="col" class="px-6 py-3">
          Typ
        </th>
        <th scope="col" class="px-6 py-3">
          Registriert am
        </th>
        <th scope="col" class="px-6 py-3">
          Letztes Login
        </th>
        <th scope="col" class="px-6 py-3">
          Anfragen
        </th>
        <th scope="col" class="px-6 py-3">
          Aktionen
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="bg-white border-b hover:bg-gray-50" *ngFor="let user of entitiesPaginated?.data">
        <td class="px-6 py-4 font-medium text-gray-900" colspan="2">
          {{ user.firstName }} {{ user.lastName }}
        </td>
        <td class="px-6 py-4 items-center" colspan="2">
          <div class="flex items-center gap-2">
            <div class="w-8 h-8">
              <ng-container *ngIf="user.isConfirmed">
                <svg class="text-green-400" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"></path>
                </svg>
              </ng-container>
              <ng-container *ngIf="!user.isConfirmed">
                <svg class="text-red-600" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </ng-container>
            </div>
            <a href="mailto:{{ user.email }}">{{ user.email }}</a>
          </div>
        </td>
        <td class="px-6 py-4">
          {{ 'enums.userType.' + user.userType | translate }}
        </td>
        <td class="px-6 py-4">
          {{ user.createdAt | date:'medium' }}
        </td>
        <td class="px-6 py-4">
          {{ user.lastLoginAt | date:'medium' }}
        </td>
        <td class="px-6 py-4">
          <p>{{ user.apprenticeshipRequests?.length }}</p>
        </td>
        <td class="px-6 py-4">
          <a routerLink="/users/{{user.id}}" class="font-medium text-blue-600 hover:underline">
            Details
          </a>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td class="p-4">
          <jofi-pagination *ngIf="entitiesPaginated?.meta && entitiesPaginated?.meta?.totalPages"
                           (pageSelected)="goToPage($event)"
                           [currentPage]="entitiesPaginated?.meta?.currentPage"
                           [totalPages]="entitiesPaginated?.meta?.totalPages"></jofi-pagination>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</div>
