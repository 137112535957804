<div>
  <h1 class="text-2xl mb-4">Partner erstellen</h1>

  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <form [formGroup]="newEntityForm" (ngSubmit)="save()" novalidate class="space-y-6">
      <div>
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Name
        </label>
        <input type="text" name="name" id="name"
               formControlName="name"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               placeholder="Name" required>
        <small class="form-text text-red-600 block mb-4"
               *ngIf="newEntityForm.controls['name'].touched && newEntityForm.controls['name'].hasError('required')">
          Name {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <div>
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Website
        </label>
        <input type="text" name="website" id="website"
               formControlName="website"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               placeholder="Website" required>
        <small class="form-text text-red-600 block mb-4"
               *ngIf="newEntityForm.controls['website'].touched && newEntityForm.controls['website'].hasError('required')">
          Website {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <div>
        <label class="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" class="sr-only peer" name="isVisible" formControlName="isVisible">
          <div
            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            Auf der Landing-Page von Jofi anzeigen
          </span>
        </label>
      </div>
      <button type="submit"
              [disabled]="!newEntityForm.valid"
              class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        {{ 'global.ui.save' | translate }}
      </button>
    </form>
  </div>
</div>
