import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'jofi-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['login.page.scss'],
})
export class LoginPage implements OnDestroy {
  constructor() {
  }

  ngOnDestroy(): void {
  }
}
