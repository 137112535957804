/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JofiApprenticeship } from '../models/jofi-apprenticeship';

@Injectable({
  providedIn: 'root',
})
export class JofiContentDeliveryApprenticeshipsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apprenticeshipsDeliveryControllerFindAllByCompanyId
   */
  static readonly ApprenticeshipsDeliveryControllerFindAllByCompanyIdPath = '/v1/content-delivery/apprenticeships/byCompanyId/{companyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apprenticeshipsDeliveryControllerFindAllByCompanyId()` instead.
   *
   * This method doesn't expect any request body.
   */
  apprenticeshipsDeliveryControllerFindAllByCompanyId$Response(params: {
    companyId: string;

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<JofiApprenticeship>>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentDeliveryApprenticeshipsService.ApprenticeshipsDeliveryControllerFindAllByCompanyIdPath, 'get');
    if (params) {
      rb.path('companyId', params.companyId, {});
      rb.header('x-api-key', params['x-api-key'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<JofiApprenticeship>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apprenticeshipsDeliveryControllerFindAllByCompanyId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apprenticeshipsDeliveryControllerFindAllByCompanyId(params: {
    companyId: string;

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
  }
): Observable<Array<JofiApprenticeship>> {

    return this.apprenticeshipsDeliveryControllerFindAllByCompanyId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<JofiApprenticeship>>) => r.body as Array<JofiApprenticeship>)
    );
  }

  /**
   * Path part for operation apprenticeshipsDeliveryControllerFindById
   */
  static readonly ApprenticeshipsDeliveryControllerFindByIdPath = '/v1/content-delivery/apprenticeships/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apprenticeshipsDeliveryControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  apprenticeshipsDeliveryControllerFindById$Response(params: {
    id: string;

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiApprenticeship>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentDeliveryApprenticeshipsService.ApprenticeshipsDeliveryControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('x-api-key', params['x-api-key'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiApprenticeship>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apprenticeshipsDeliveryControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apprenticeshipsDeliveryControllerFindById(params: {
    id: string;

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
  }
): Observable<JofiApprenticeship> {

    return this.apprenticeshipsDeliveryControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<JofiApprenticeship>) => r.body as JofiApprenticeship)
    );
  }

}
