/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { JofiAdminCompaniesService } from './services/jofi-admin-companies.service';
import { JofiAdminInterestsService } from './services/jofi-admin-interests.service';
import { JofiAdminInterestGroupsService } from './services/jofi-admin-interest-groups.service';
import { JofiAdminPartnersService } from './services/jofi-admin-partners.service';
import { JofiAdminProfessionsService } from './services/jofi-admin-professions.service';
import { JofiAdminStatsService } from './services/jofi-admin-stats.service';
import { JofiAdminUsersService } from './services/jofi-admin-users.service';
import { JofiAdminStudentProfilesPublicService } from './services/jofi-admin-student-profiles-public.service';
import { JofiAuthenticationService } from './services/jofi-authentication.service';
import { JofiContentDeliveryApprenticeshipsService } from './services/jofi-content-delivery-apprenticeships.service';
import { JofiContentDeliveryCantonsService } from './services/jofi-content-delivery-cantons.service';
import { JofiContentDeliveryCompaniesService } from './services/jofi-content-delivery-companies.service';
import { JofiContentDeliveryPartnersService } from './services/jofi-content-delivery-partners.service';
import { JofiContentDeliveryProfessionsService } from './services/jofi-content-delivery-professions.service';
import { JofiContentManagementApprenticeshipsService } from './services/jofi-content-management-apprenticeships.service';
import { JofiContentManagementApprenticeshipRequestsService } from './services/jofi-content-management-apprenticeship-requests.service';
import { JofiContentManagementCompaniesService } from './services/jofi-content-management-companies.service';
import { JofiContentManagementInterestsService } from './services/jofi-content-management-interests.service';
import { JofiContentManagementInterestGroupsService } from './services/jofi-content-management-interest-groups.service';
import { JofiContentManagementMatchingService } from './services/jofi-content-management-matching.service';
import { JofiContentManagementStudentProfilesService } from './services/jofi-content-management-student-profiles.service';
import { JofiContentManagementUploadsService } from './services/jofi-content-management-uploads.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    JofiAdminCompaniesService,
    JofiAdminInterestsService,
    JofiAdminInterestGroupsService,
    JofiAdminPartnersService,
    JofiAdminProfessionsService,
    JofiAdminStatsService,
    JofiAdminUsersService,
    JofiAdminStudentProfilesPublicService,
    JofiAuthenticationService,
    JofiContentDeliveryApprenticeshipsService,
    JofiContentDeliveryCantonsService,
    JofiContentDeliveryCompaniesService,
    JofiContentDeliveryPartnersService,
    JofiContentDeliveryProfessionsService,
    JofiContentManagementApprenticeshipsService,
    JofiContentManagementApprenticeshipRequestsService,
    JofiContentManagementCompaniesService,
    JofiContentManagementInterestsService,
    JofiContentManagementInterestGroupsService,
    JofiContentManagementMatchingService,
    JofiContentManagementStudentProfilesService,
    JofiContentManagementUploadsService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
