<div>
  <h1 class="text-2xl mb-4">Unternehmen zur Überprüfung</h1>

  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" class="px-6 py-3">
          Name
        </th>
        <th scope="col" class="px-6 py-3">
          Adresse
        </th>
        <th scope="col" class="px-6 py-3">
          Kontakt
        </th>
        <th scope="col" class="px-6 py-3">
          Registriert am
        </th>
        <th scope="col" class="px-6 py-3">
          Freigeschaltet
        </th>
        <th scope="col" class="px-6 py-3">
          Aktionen
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="bg-white border-b hover:bg-gray-50" *ngFor="let company of companies">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
          {{ company.name }}
        </th>
        <td class="px-6 py-4">
          {{ company.street }}<br>
          {{ company.zipCode }} {{ company.city }} ({{ company.canton.abbreviation }})
        </td>
        <td class="px-6 py-4">
          <a href="mailto:{{ company.email }}">{{ company.email }}</a><br>
          <a href="tel:{{ company.phone }}">{{ company.phone }}</a>
        </td>
        <td class="px-6 py-4">
          {{ company.createdAt | date }}
        </td>
        <td class="px-6 py-4">
          <div class="w-8 h-8">
            <ng-container *ngIf="company.isConfirmed">
              <svg class="text-green-400" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"></path>
              </svg>
            </ng-container>
            <ng-container *ngIf="!company.isConfirmed">
              <svg class="text-red-600" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
            </ng-container>
          </div>
        </td>
        <td class="px-6 py-4">
          <a *ngIf="company.members && company.members.length"
             routerLink="/users/{{company.members[0].userId}}"
             class="font-medium text-blue-600 hover:underline">
            Details
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
