import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { JofiAdminPartnersService } from '../../_generated/jofi-rest-api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'jofi-partners-new-page',
  templateUrl: './partners-new.page.html',
  styleUrls: ['partners-new.page.scss'],
})
export class PartnersNewPage implements OnDestroy {
  public newEntityForm: FormGroup;

  constructor(
    private readonly store: Store,
    private readonly jofiAdminPartnersService: JofiAdminPartnersService,
    private readonly router: Router,
  ) {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.newEntityForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      website: new FormControl('', [Validators.required, Validators.pattern(urlRegex)]),
      isVisible: new FormControl(true, []),
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  public save() {
    if (this.newEntityForm.valid) {
      this.jofiAdminPartnersService.partnersAdminControllerCreate({ body: this.newEntityForm.value })
        .subscribe({
          next: () => {
            swal.fire('Gespeichert!', '', 'success');
            this.router.navigateByUrl('/partners').then();
          },
        });
    }
  }
}
