import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateToAge' })
export class DateToAgePipe implements PipeTransform {
  transform(value: string | undefined) {
    if (value) {
      return moment().diff(value, 'years');
    }
    return '';
  }
}
