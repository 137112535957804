/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JofiAcceptInvitationDto } from '../models/jofi-accept-invitation-dto';
import { JofiCompany } from '../models/jofi-company';
import { JofiCreateCompanyDto } from '../models/jofi-create-company-dto';
import { JofiCreateInvitationDto } from '../models/jofi-create-invitation-dto';
import { JofiRole } from '../models/jofi-role';
import { JofiSuccessDto } from '../models/jofi-success-dto';
import { JofiUpdateCompanyDto } from '../models/jofi-update-company-dto';
import { JofiUpdateRoleDto } from '../models/jofi-update-role-dto';

@Injectable({
  providedIn: 'root',
})
export class JofiContentManagementCompaniesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation companiesControllerFindById
   */
  static readonly CompaniesControllerFindByIdPath = '/v1/content-management/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesControllerFindById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiCompany>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementCompaniesService.CompaniesControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiCompany>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesControllerFindById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<JofiCompany> {

    return this.companiesControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<JofiCompany>) => r.body as JofiCompany)
    );
  }

  /**
   * Path part for operation companiesControllerRemove
   */
  static readonly CompaniesControllerRemovePath = '/v1/content-management/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesControllerRemove$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementCompaniesService.CompaniesControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesControllerRemove(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.companiesControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation companiesControllerUpdate
   */
  static readonly CompaniesControllerUpdatePath = '/v1/content-management/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesControllerUpdate$Response(params: {
    id: string;
    context?: HttpContext
    body: JofiUpdateCompanyDto
  }
): Observable<StrictHttpResponse<JofiCompany>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementCompaniesService.CompaniesControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiCompany>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesControllerUpdate(params: {
    id: string;
    context?: HttpContext
    body: JofiUpdateCompanyDto
  }
): Observable<JofiCompany> {

    return this.companiesControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiCompany>) => r.body as JofiCompany)
    );
  }

  /**
   * Path part for operation companiesControllerFindMembers
   */
  static readonly CompaniesControllerFindMembersPath = '/v1/content-management/companies/{id}/members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesControllerFindMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesControllerFindMembers$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<JofiRole>>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementCompaniesService.CompaniesControllerFindMembersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<JofiRole>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesControllerFindMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesControllerFindMembers(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<JofiRole>> {

    return this.companiesControllerFindMembers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<JofiRole>>) => r.body as Array<JofiRole>)
    );
  }

  /**
   * Path part for operation companiesControllerUpdateMember
   */
  static readonly CompaniesControllerUpdateMemberPath = '/v1/content-management/companies/{id}/members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesControllerUpdateMember()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesControllerUpdateMember$Response(params: {
    id: string;
    context?: HttpContext
    body: JofiUpdateRoleDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementCompaniesService.CompaniesControllerUpdateMemberPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesControllerUpdateMember$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesControllerUpdateMember(params: {
    id: string;
    context?: HttpContext
    body: JofiUpdateRoleDto
  }
): Observable<void> {

    return this.companiesControllerUpdateMember$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation companiesControllerCreate
   */
  static readonly CompaniesControllerCreatePath = '/v1/content-management/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesControllerCreate$Response(params: {
    context?: HttpContext
    body: JofiCreateCompanyDto
  }
): Observable<StrictHttpResponse<JofiCompany>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementCompaniesService.CompaniesControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiCompany>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesControllerCreate(params: {
    context?: HttpContext
    body: JofiCreateCompanyDto
  }
): Observable<JofiCompany> {

    return this.companiesControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiCompany>) => r.body as JofiCompany)
    );
  }

  /**
   * Path part for operation companiesControllerCreateInvitation
   */
  static readonly CompaniesControllerCreateInvitationPath = '/v1/content-management/companies/{id}/members/createInvitation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesControllerCreateInvitation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesControllerCreateInvitation$Response(params: {
    context?: HttpContext
    body: JofiCreateInvitationDto
  }
): Observable<StrictHttpResponse<JofiSuccessDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementCompaniesService.CompaniesControllerCreateInvitationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiSuccessDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesControllerCreateInvitation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesControllerCreateInvitation(params: {
    context?: HttpContext
    body: JofiCreateInvitationDto
  }
): Observable<JofiSuccessDto> {

    return this.companiesControllerCreateInvitation$Response(params).pipe(
      map((r: StrictHttpResponse<JofiSuccessDto>) => r.body as JofiSuccessDto)
    );
  }

  /**
   * Path part for operation companiesControllerAcceptInvitation
   */
  static readonly CompaniesControllerAcceptInvitationPath = '/v1/content-management/companies/{id}/members/acceptInvitation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesControllerAcceptInvitation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesControllerAcceptInvitation$Response(params: {
    context?: HttpContext
    body: JofiAcceptInvitationDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementCompaniesService.CompaniesControllerAcceptInvitationPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesControllerAcceptInvitation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesControllerAcceptInvitation(params: {
    context?: HttpContext
    body: JofiAcceptInvitationDto
  }
): Observable<void> {

    return this.companiesControllerAcceptInvitation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation companiesControllerDeleteInvitation
   */
  static readonly CompaniesControllerDeleteInvitationPath = '/v1/content-management/companies/{id}/members/{invitationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesControllerDeleteInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesControllerDeleteInvitation$Response(params: {
    id: string;
    invitationId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementCompaniesService.CompaniesControllerDeleteInvitationPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('invitationId', params.invitationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesControllerDeleteInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesControllerDeleteInvitation(params: {
    id: string;
    invitationId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.companiesControllerDeleteInvitation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
