import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '../state/app/app.state';

@Injectable()
export class RedirectIfLoggedInGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLoggedIn = this.store.selectSnapshot(AppState.isLoggedIn);
    if (isLoggedIn) {
      this.router.navigateByUrl('/dashboard').then();
    }
    return !isLoggedIn;
  }
}
