import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import {
  JofiAdminStatsService,
  JofiAdminUsersService,
  JofiStatsApprenticeshipsDto,
  JofiStatsRegistrationsDto,
  JofiUser,
  JofiUserTypeEnum,
} from '../../_generated/jofi-rest-api';
import { JofiCurrentSchoolYearEnum } from '../../_generated/jofi-rest-api/models/jofi-current-school-year-enum';

@Component({
  selector: 'jofi-dashboard-page',
  templateUrl: './dashboard.page.html',
  styleUrls: ['dashboard.page.scss'],
})
export class DashboardPage implements OnDestroy {
  public user$ = this.store.select(AppState.user);
  public registrationStats: JofiStatsRegistrationsDto | undefined;
  public apprenticeshipStats: JofiStatsApprenticeshipsDto | undefined;
  public allUsers: JofiUser[] = [];
  public allStudents: JofiUser[] = [];

  constructor(
    private readonly store: Store,
    private readonly jofiAdminStatsService: JofiAdminStatsService,
    private readonly jofiAdminUsersService: JofiAdminUsersService,
  ) {
  }

  ngOnInit() {
    this.initializeStats();
    this.initializeUsers();
  }

  ngOnDestroy(): void {
  }

  public filterConfirmed(t: JofiUser): boolean {
    return t.isConfirmed;
  }

  public filterCantonBL(t: JofiUser): boolean {
    try {
      return t.studentProfilePrivate.canton.name === 'Basel-Landschaft';
    } catch (exception) {
      return false;
    }
  }

  public filterCantonBs(t: JofiUser): boolean {
    try {
      return t.studentProfilePrivate.canton.name === 'Basel-Stadt';
    } catch (exception) {
      return false;
    }
  }

  public filterCantonAg(t: JofiUser): boolean {
    try {
      return t.studentProfilePrivate.canton.name === 'Aargau';
    } catch (exception) {
      return false;
    }
  }

  public filter8Klasse(t: JofiUser): boolean {
    return t.studentProfilePublic && t.studentProfilePublic.currentSchoolYear === JofiCurrentSchoolYearEnum.EnumsCurrentSchoolYearYear8;
  }

  public filter9Klasse(t: JofiUser): boolean {
    return t.studentProfilePublic && t.studentProfilePublic.currentSchoolYear === JofiCurrentSchoolYearEnum.EnumsCurrentSchoolYearYear9;
  }

  public filter10Klasse(t: JofiUser): boolean {
    return t.studentProfilePublic && t.studentProfilePublic.currentSchoolYear === JofiCurrentSchoolYearEnum.EnumsCurrentSchoolYearYear10;
  }

  public getPercentage(amount: number, totalAmount: number) {
    return Math.ceil(amount / totalAmount * 100) + '%';
  }

  private initializeUsers() {
    this.jofiAdminUsersService.usersAdminControllerFindAll().subscribe(users => {
      this.allStudents = users.filter(user => user.userType === JofiUserTypeEnum.Student);
    });
  }

  private initializeStats() {
    this.jofiAdminStatsService.statsAdminControllerFindRegistrationStats().subscribe({
      next: registrationStats => {
        this.registrationStats = registrationStats;
      },
    });
    this.jofiAdminStatsService.statsAdminControllerFindApprenticeshipsStats().subscribe({
      next: apprenticeshipStats => {
        this.apprenticeshipStats = apprenticeshipStats;
      },
    });
  }
}
