import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { JofiAdminCompaniesService, JofiAdminUsersService, JofiCompany } from '../../_generated/jofi-rest-api';
import { ActivatedRoute } from '@angular/router';
import { Paginated } from 'nestjs-paginate';

@Component({
  selector: 'jofi-todos-page',
  templateUrl: './todos.page.html',
  styleUrls: ['todos.page.scss'],
})
export class TodosPage implements OnDestroy {
  public companies: JofiCompany[] = [];

  constructor(
    private readonly store: Store,
    private readonly jofiAdminUsersService: JofiAdminUsersService,
    private readonly jofiAdminCompaniesService: JofiAdminCompaniesService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy(): void {
  }

  private initialize() {
    this.jofiAdminCompaniesService.companiesAdminControllerFindAllPaginated({
      'filter.isConfirmed': '$eq:false',
    }).subscribe({
      next: (companies: any) => {
        this.companies = (companies as Paginated<JofiCompany>).data;
      },
    });
  }
}
