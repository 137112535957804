/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JofiStatsApprenticeshipsDto } from '../models/jofi-stats-apprenticeships-dto';
import { JofiStatsRegistrationsDto } from '../models/jofi-stats-registrations-dto';

@Injectable({
  providedIn: 'root',
})
export class JofiAdminStatsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation statsAdminControllerFindRegistrationStats
   */
  static readonly StatsAdminControllerFindRegistrationStatsPath = '/v1/admin/stats/registrations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statsAdminControllerFindRegistrationStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  statsAdminControllerFindRegistrationStats$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiStatsRegistrationsDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminStatsService.StatsAdminControllerFindRegistrationStatsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiStatsRegistrationsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statsAdminControllerFindRegistrationStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statsAdminControllerFindRegistrationStats(params?: {
    context?: HttpContext
  }
): Observable<JofiStatsRegistrationsDto> {

    return this.statsAdminControllerFindRegistrationStats$Response(params).pipe(
      map((r: StrictHttpResponse<JofiStatsRegistrationsDto>) => r.body as JofiStatsRegistrationsDto)
    );
  }

  /**
   * Path part for operation statsAdminControllerFindApprenticeshipsStats
   */
  static readonly StatsAdminControllerFindApprenticeshipsStatsPath = '/v1/admin/stats/apprenticeships';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statsAdminControllerFindApprenticeshipsStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  statsAdminControllerFindApprenticeshipsStats$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiStatsApprenticeshipsDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminStatsService.StatsAdminControllerFindApprenticeshipsStatsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiStatsApprenticeshipsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statsAdminControllerFindApprenticeshipsStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statsAdminControllerFindApprenticeshipsStats(params?: {
    context?: HttpContext
  }
): Observable<JofiStatsApprenticeshipsDto> {

    return this.statsAdminControllerFindApprenticeshipsStats$Response(params).pipe(
      map((r: StrictHttpResponse<JofiStatsApprenticeshipsDto>) => r.body as JofiStatsApprenticeshipsDto)
    );
  }

}
