import { JofiUpload, JofiUser } from '../../_generated/jofi-rest-api';

export class AppStateSetAuthentication {
  static readonly type = '[app] Set authentication';

  constructor(public accessToken: string, public refreshToken: string) {
  }
}

export class AppStateSetUser {
  static readonly type = '[app] Set user';

  constructor(public user: JofiUser) {
  }
}

export class AppStateSetProfileImage {
  static readonly type = '[app] Set profile image';

  constructor(public profileImage: JofiUpload | undefined) {
  }
}
