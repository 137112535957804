import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  JofiAdminCompaniesService,
  JofiAdminUsersService,
  JofiUser,
  JofiUserTypeEnum,
} from '../../_generated/jofi-rest-api';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { getApiErrorMessageFromResponse } from '../../../_common/api-error.handler';

@Component({
  selector: 'jofi-users-detail-page',
  templateUrl: './users-detail.page.html',
  styleUrls: ['users-detail.page.scss'],
})
export class UsersDetailPage implements OnDestroy {
  public user: JofiUser | undefined;
  public jofiUserTypeEnum = JofiUserTypeEnum;

  constructor(
    private readonly store: Store,
    private readonly jofiAdminUsersService: JofiAdminUsersService,
    private readonly jofiAdminCompaniesService: JofiAdminCompaniesService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params && params['id']) {
        this.load(params['id']);
      }
    });
  }

  ngOnDestroy(): void {
  }

  public confirmCompany(companyId: string | undefined) {
    if (companyId && this.user?.id) {
      this.jofiAdminCompaniesService.companiesAdminControllerConfirm({
        companyId: companyId,
        userId: this.user?.id,
      }).subscribe({
        next: () => {
          swal.fire('Freigeschaltet', `Das Unternehmen wurde freigeschaltet und ist nun sichtbar auf Jofi. ${this.user?.firstName} ${this.user?.lastName} wurde darüber per E-Mail informiert.`, 'success');
        },
      });
    }
  }

  public save() {
    if (this.user && this.user.id) {
      this.jofiAdminUsersService.usersAdminControllerUpdate({
        id: this.user.id,
        body: this.user,
      }).subscribe({
        next: () => {
          swal.fire('Gespeichert!', '', 'success');
        },
      });
    }
  }

  public setUserType(event: any) {
    if (this.user && this.user.userType) {
      this.user.userType = event.target.value;
    }
  }

  public sendVerificationMail() {
    if (this.user && this.user?.id) {
      this.jofiAdminUsersService.usersAdminControllerResendVerificationMail({
        id: this.user?.id,
      }).subscribe({
        next: () => {
          swal.fire('E-Mail gesendet!', '', 'success');
        },
        error: apiException => {
          swal.fire('Fehler', getApiErrorMessageFromResponse(apiException), 'error');
        },
      });
    }
  }

  public delete() {
    if (this.user && this.user?.id) {
      this.jofiAdminUsersService.usersAdminControllerDelete({
        id: this.user?.id,
      }).subscribe({
        next: () => {
          swal.fire('Benutzer gelöscht!', '', 'success');
          this.router.navigateByUrl('/users').then();
        },
        error: apiException => {
          swal.fire('Fehler', getApiErrorMessageFromResponse(apiException), 'error');
        },
      });
    }
  }

  private load(id: string) {
    this.jofiAdminUsersService.usersAdminControllerFindById({
      id: id,
    }).subscribe({
      next: user => {
        this.user = user;
      },
    });
  }
}
