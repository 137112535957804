<div>
  <h1 class="text-2xl mb-4">Interesse erstellen</h1>

  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <form [formGroup]="newEntityForm" (ngSubmit)="save()" novalidate class="space-y-6">
      <div>
        <label for="textForCompany" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Text für Firmen
        </label>
        <input type="text" name="textForCompany" id="textForCompany"
               formControlName="textForCompany"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               placeholder="Text für Firmen" required>
        <small class="form-text text-red-600 block mb-4"
               *ngIf="newEntityForm.controls['textForCompany'].touched && newEntityForm.controls['textForCompany'].hasError('required')">
          Text für Firmen {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <div>
        <label for="textForStudent" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Text für Schüler:in
        </label>
        <input type="text" name="textForStudent" id="textForStudent"
               formControlName="textForStudent"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               placeholder="Text für Schüler:in" required>
        <small class="form-text text-red-600 block mb-4"
               *ngIf="newEntityForm.controls['textForStudent'].touched && newEntityForm.controls['textForStudent'].hasError('required')">
          Text für Schüler:in {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <div>
        <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Beschreibung
        </label>
        <input type="text" name="description" id="description"
               formControlName="description"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               placeholder="Text für Firmen">
      </div>
      <div>
        <label for="interestGroupId" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Interessensgruppe
        </label>
        <select id="interestGroupId"
                (change)="setInterestGroupId($event)"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option *ngFor="let interestGroup of interestGroups" [value]="interestGroup.id">
            {{ interestGroup.name }}
          </option>
        </select>
      </div>

      <button type="submit"
              [disabled]="!newEntityForm.valid"
              class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        {{ 'global.ui.save' | translate }}
      </button>
    </form>
  </div>
</div>
