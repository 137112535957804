import { Component, OnDestroy } from '@angular/core';
import { JofiAdminStudentProfilesPublicService, JofiStudentProfilePublic } from '../../_generated/jofi-rest-api';
import { Paginated } from 'nestjs-paginate';

interface SortByOption {
  property: string;
  direction: 'ASC' | 'DESC';
}

@Component({
  selector: 'jofi-users-page',
  templateUrl: './student-profiles-public.page.html',
  styleUrls: ['student-profiles-public.page.scss'],
})
export class StudentProfilesPublicPage implements OnDestroy {
  public entitiesPaginated: Paginated<JofiStudentProfilePublic> | undefined;
  public searchKeyWord: string = '';
  public currentSortBy: SortByOption = {
    property: 'createdAt',
    direction: 'DESC',
  };
  public sortByOptions: SortByOption[] = [
    {
      property: 'firstName',
      direction: 'ASC',
    },
    {
      property: 'firstName',
      direction: 'DESC',
    },
    {
      property: 'lastName',
      direction: 'ASC',
    },
    {
      property: 'lastName',
      direction: 'DESC',
    },
    {
      property: 'userType',
      direction: 'ASC',
    },
    {
      property: 'userType',
      direction: 'DESC',
    },
    {
      property: 'createdAt',
      direction: 'ASC',
    },
    {
      property: 'createdAt',
      direction: 'DESC',
    },
    {
      property: 'lastLoginAt',
      direction: 'ASC',
    },
    {
      property: 'lastLoginAt',
      direction: 'DESC',
    },
    {
      property: 'apprenticeshipRequests.id',
      direction: 'ASC',
    },
    {
      property: 'apprenticeshipRequests.id',
      direction: 'DESC',
    },
  ];
  public isSortByMenuOpen = false;

  constructor(
    private readonly jofiAdminStudentProfilesPublicService: JofiAdminStudentProfilesPublicService,
  ) {
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy(): void {
  }

  public search() {
    if (this.searchKeyWord) {
      this.load(1, this.searchKeyWord);
    } else {
      this.reset();
    }
  }

  public reset() {
    this.searchKeyWord = '';
    this.load();
  }

  public goToPage(page: number) {
    this.load(page);
  }

  public toggleSortByMenu() {
    this.isSortByMenuOpen = !this.isSortByMenuOpen;
  }

  public setCurrentSortBy(sortByOption: SortByOption) {
    this.isSortByMenuOpen = false;
    this.currentSortBy = sortByOption;
    this.load();
  }

  private load(pageToLoad?: number, search: string = '') {
    if (!pageToLoad) {
      pageToLoad = 1;
    }
    this.jofiAdminStudentProfilesPublicService.studentProfilesPublicAdminControllerFindAllPaginated({
      page: pageToLoad,
      search: search,
      sortBy: `${this.currentSortBy.property}:${this.currentSortBy.direction}`,
    }).subscribe({
      next: (result: any) => {
        this.entitiesPaginated = result;
      },
    });
  }
}
