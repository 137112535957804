import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { JofiAdminProfessionsService } from '../../_generated/jofi-rest-api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'jofi-professions-new-page',
  templateUrl: './professions-new.page.html',
  styleUrls: ['professions-new.page.scss'],
})
export class ProfessionsNewPage implements OnDestroy {
  public newEntityForm: FormGroup;

  constructor(
    private readonly store: Store,
    private readonly jofiAdminProfessionsService: JofiAdminProfessionsService,
    private readonly router: Router,
  ) {
    this.newEntityForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', []),
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  public save() {
    if (this.newEntityForm.valid) {
      this.jofiAdminProfessionsService.professionsAdminControllerCreate({ body: this.newEntityForm.value })
        .subscribe({
          next: () => {
            swal.fire('Gespeichert!', '', 'success');
            this.router.navigateByUrl('/professions').then();
          },
        });
    }
  }
}
