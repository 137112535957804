import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  JofiAdminInterestGroupsService,
  JofiAdminInterestsService,
  JofiInterest,
  JofiInterestGroup,
} from '../../_generated/jofi-rest-api';
import swal from 'sweetalert2';

@Component({
  selector: 'jofi-interests-page',
  templateUrl: './interests.page.html',
  styleUrls: ['interests.page.scss'],
})
export class InterestsPage implements OnDestroy {
  public interests: JofiInterest[] = [];
  public interestGroups: JofiInterestGroup[] = [];

  constructor(
    private readonly store: Store,
    private readonly jofiAdminInterestsService: JofiAdminInterestsService,
    private readonly jofiAdminInterestGroupsService: JofiAdminInterestGroupsService,
  ) {
  }

  ngOnInit() {
    this.load();
    this.initializeInterestGroups();
  }

  ngOnDestroy(): void {
  }

  public updateInterest(interest: JofiInterest) {
    if (interest && interest.id) {
      this.jofiAdminInterestsService.interestsAdminControllerUpdate({
        id: interest.id,
        body: interest,
      }).subscribe({
        next: () => {
          swal.fire('Gespeichert!', '', 'success');
        },
      });
    }
  }

  private load() {
    this.jofiAdminInterestsService.interestsAdminControllerFindAll().subscribe({
      next: interests => {
        this.interests = interests;
      },
    });
  }

  private initializeInterestGroups() {
    this.jofiAdminInterestGroupsService.interestGroupsAdminControllerFindAll().subscribe({
      next: interestGroups => {
        if (interestGroups && interestGroups.length) {
          this.interestGroups = interestGroups;
        }
      },
    });
  }
}
