import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { JofiAdminPartnersService, JofiPartner, JofiUpload } from '../../_generated/jofi-rest-api';
import swal from 'sweetalert2';

@Component({
  selector: 'jofi-partners-page',
  templateUrl: './partners.page.html',
  styleUrls: ['partners.page.scss'],
})
export class PartnersPage implements OnDestroy {
  public partners: JofiPartner[] = [];

  constructor(
    private readonly store: Store,
    private readonly jofiAdminPartnersService: JofiAdminPartnersService,
  ) {
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy(): void {
  }

  public update(entity: JofiPartner) {
    if (entity && entity.id) {
      this.jofiAdminPartnersService.partnersAdminControllerUpdate({
        id: entity.id,
        body: entity,
      }).subscribe({
        next: () => {
          swal.fire('Gespeichert!', '', 'success');
        },
      });
    }
  }

  public saveUploadForPartner(upload: JofiUpload, partner: JofiPartner) {
    this.jofiAdminPartnersService.partnersAdminControllerUpdate({
      id: partner.id,
      body: {
        id: partner.id,
        uploadId: upload.id,
      },
    }).subscribe({
      next: () => {
        swal.fire('Gespeichert!', '', 'success');
        this.load();
      },
    });
  }

  private load() {
    this.jofiAdminPartnersService.partnersAdminControllerFindAll().subscribe({
      next: entities => {
        this.partners = entities;
      },
    });
  }
}
