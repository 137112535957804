/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JofiCompany } from '../models/jofi-company';
import { JofiUpdateCompanyAdminDto } from '../models/jofi-update-company-admin-dto';
import { JofiUser } from '../models/jofi-user';

@Injectable({
  providedIn: 'root',
})
export class JofiAdminCompaniesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation companiesAdminControllerFindAllPaginated
   */
  static readonly CompaniesAdminControllerFindAllPaginatedPath = '/v1/admin/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesAdminControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesAdminControllerFindAllPaginated$Response(params?: {
    'filter.isConfirmed'?: any;
    sortBy?: any;
    search?: any;
    limit?: any;
    page?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<JofiCompany>>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminCompaniesService.CompaniesAdminControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('filter.isConfirmed', params['filter.isConfirmed'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<JofiCompany>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesAdminControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesAdminControllerFindAllPaginated(params?: {
    'filter.isConfirmed'?: any;
    sortBy?: any;
    search?: any;
    limit?: any;
    page?: any;
    context?: HttpContext
  }
): Observable<Array<JofiCompany>> {

    return this.companiesAdminControllerFindAllPaginated$Response(params).pipe(
      map((r: StrictHttpResponse<Array<JofiCompany>>) => r.body as Array<JofiCompany>)
    );
  }

  /**
   * Path part for operation companiesAdminControllerFindById
   */
  static readonly CompaniesAdminControllerFindByIdPath = '/v1/admin/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesAdminControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesAdminControllerFindById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiCompany>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminCompaniesService.CompaniesAdminControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiCompany>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesAdminControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesAdminControllerFindById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<JofiCompany> {

    return this.companiesAdminControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<JofiCompany>) => r.body as JofiCompany)
    );
  }

  /**
   * Path part for operation companiesAdminControllerDelete
   */
  static readonly CompaniesAdminControllerDeletePath = '/v1/admin/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesAdminControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesAdminControllerDelete$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiCompany>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminCompaniesService.CompaniesAdminControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiCompany>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesAdminControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesAdminControllerDelete(params: {
    id: string;
    context?: HttpContext
  }
): Observable<JofiCompany> {

    return this.companiesAdminControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<JofiCompany>) => r.body as JofiCompany)
    );
  }

  /**
   * Path part for operation companiesAdminControllerUpdate
   */
  static readonly CompaniesAdminControllerUpdatePath = '/v1/admin/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesAdminControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesAdminControllerUpdate$Response(params: {
    id: string;
    context?: HttpContext
    body: JofiUpdateCompanyAdminDto
  }
): Observable<StrictHttpResponse<JofiCompany>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminCompaniesService.CompaniesAdminControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiCompany>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesAdminControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companiesAdminControllerUpdate(params: {
    id: string;
    context?: HttpContext
    body: JofiUpdateCompanyAdminDto
  }
): Observable<JofiCompany> {

    return this.companiesAdminControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiCompany>) => r.body as JofiCompany)
    );
  }

  /**
   * Path part for operation companiesAdminControllerConfirm
   */
  static readonly CompaniesAdminControllerConfirmPath = '/v1/admin/companies/{companyId}/{userId}/confirm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companiesAdminControllerConfirm()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesAdminControllerConfirm$Response(params: {
    companyId: string;
    userId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiUser>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminCompaniesService.CompaniesAdminControllerConfirmPath, 'patch');
    if (params) {
      rb.path('companyId', params.companyId, {});
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companiesAdminControllerConfirm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companiesAdminControllerConfirm(params: {
    companyId: string;
    userId: string;
    context?: HttpContext
  }
): Observable<JofiUser> {

    return this.companiesAdminControllerConfirm$Response(params).pipe(
      map((r: StrictHttpResponse<JofiUser>) => r.body as JofiUser)
    );
  }

}
