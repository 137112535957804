import { Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { map, switchMap } from 'rxjs/operators';
import { JofiContentManagementUploadsService } from '../../_generated/jofi-rest-api';

@Component({
  selector: 'secured-image',
  template: `
    <img [src]="dataUrl$|async" class="block max-h-full max-w-full object-fill" />
  `,
})
export class SecuredImageComponent implements OnChanges {
  // This part just creates an rxjs stream from the src
  // this makes sure that we can handle it when the src changes
  // or even when the component gets destroyed
  @Input() uploadId: string | undefined = undefined;
  @Input() userId: string | undefined = undefined;
  @Input() companyId: string | undefined = undefined;
  @Input() isPublic: boolean = false;
  @Input() apprenticeshipRequestId: string | undefined = undefined;
  private src$ = new BehaviorSubject(this.uploadId);

  ngOnChanges(): void {
    this.src$.next(this.uploadId);
  }

  // this stream will contain the actual url that our img tag will load
  // everytime the src changes, the previous call would be canceled and the
  // new resource would be loaded
  dataUrl$ = this.src$.pipe(
    switchMap(url => this.loadImage(url)),
  );

  // we need HttpClient to load the image and DomSanitizer to trust the url
  constructor(
    private domSanitizer: DomSanitizer,
    private jofiContentManagementUploadsService: JofiContentManagementUploadsService,
  ) {
  }

  private loadImage(uploadId: string | undefined): Observable<any> {
    if (!uploadId) {
      uploadId = '';
    }
    if (this.companyId || this.isPublic) {
      return this.jofiContentManagementUploadsService
        .uploadsControllerDownloadPublic({
          id: uploadId,
          companyId: this.companyId || undefined,
        }).pipe(
          map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))),
        );
    }
    return this.jofiContentManagementUploadsService
      .uploadsControllerDownload({
        id: uploadId,
        userId: this.userId || undefined,
        companyId: this.companyId || undefined,
        apprenticeshipRequestId: this.apprenticeshipRequestId || undefined,
      }).pipe(
        map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))),
      );
  }
}
