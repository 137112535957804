import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'jofi-not-found-page',
  templateUrl: './not-found.page.html',
  styleUrls: ['not-found.page.scss'],
})
export class NotFoundPage implements OnDestroy {
  constructor() {
  }

  ngOnDestroy(): void {
  }
}
