<div>
  <h1 class="text-2xl mb-4 flex items-center gap-4">
    Partner, Sponsoren, Freunde und Fans
    <a routerLink="new"
       class="w-8 h-8 text-white bg-blue-700 hover:bg-blue-800 rounded-full">
      <svg class="text-white" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg"
           aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
      </svg>
    </a>
  </h1>
  <p class="mb-4">Diese Logos werden auf der Startseite von Jofi unter "Partner, Sponsoren, Freunde und Fans"
    angezeigt.</p>

  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" class="px-6 py-3">
          Logo
        </th>
        <th scope="col" class="px-6 py-3">
          Name
        </th>
        <th scope="col" class="px-6 py-3">
          Website
        </th>
        <th scope="col" class="px-6 py-3">
          Anzeigen
        </th>
        <th scope="col" class="px-6 py-3">
          Aktionen
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="bg-white border-b hover:bg-gray-50" *ngFor="let partner of partners">
        <td class="px-6 py-4">
          <div class="w-64">
            <secured-image [uploadId]="partner.uploadId"></secured-image>
          </div>
          <jofi-upload [allowImage]="true" [isPublic]="true" [autoUpload]="true"
                       (uploaded)="saveUploadForPartner($event, partner)"></jofi-upload>
        </td>
        <td class="px-6 py-4">
          <input type="text" name="name"
                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                 [(ngModel)]="partner.name">
        </td>
        <td class="px-6 py-4">
          <input type="text" name="website"
                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                 [(ngModel)]="partner.website">
        </td>
        <td>
          <label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" [checked]="partner.isVisible" class="sr-only peer" name="active"
                   [(ngModel)]="partner.isVisible">
            <div
              class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-red-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
          </label>
        </td>
        <td class="px-6 py-4">
          <button type="submit"
                  (click)="update(partner)"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            {{ 'global.ui.save' | translate }}
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
