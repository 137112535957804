<div class="jofi-upload">
  <ngx-dropzone (change)="onSelect($event)"
                [multiple]="allowMultiple"
                [accept]="allowedMimetypesString"
                [maxFileSize]="maxFilesizeInMegabytes * 1024 * 1024">
    <ngx-dropzone-label>
      <div class="icon">
        <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"></path>
        </svg>
      </div>
      Hier klicken oder {{allowedFilesString}} hierhin ziehen
    </ngx-dropzone-label>
    <ng-container *ngFor="let file of files">
      <ngx-dropzone-image-preview *ngIf="getTypeForFile(file) === 'image'"
                                  ngProjectAs="ngx-dropzone-preview"
                                  [file]="file"
                                  [removable]="true"
                                  (removed)="onRemove(file)">
      </ngx-dropzone-image-preview>
      <ngx-dropzone-video-preview *ngIf="getTypeForFile(file) === 'video'"
                                  ngProjectAs="ngx-dropzone-preview"
                                  [file]="file"
                                  [removable]="true"
                                  (removed)="onRemove(file)">
      </ngx-dropzone-video-preview>
      <ngx-dropzone-preview *ngIf="getTypeForFile(file) === 'document'"
                            ngProjectAs="ngx-dropzone-preview"
                            [file]="file"
                            [removable]="true"
                            (removed)="onRemove(file)">
        <ngx-dropzone-label>{{ file.name }}</ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ng-container>
  </ngx-dropzone>
  <ng-container *ngIf="!autoUpload">
    <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            (click)="save()"
            [disabled]="!files.length || isUploadingCount > 0">
      {{ 'global.ui.upload' | translate }}
      <i class="far fa-spinner-third fa-spin" *ngIf="isUploadingCount > 0"></i>
    </button>
  </ng-container>
  <p *ngIf="isUploadingCount > 0">
    <small>
      Das Hochladen von Dateien kann einen Moment dauern, bitte haben Sie einen Moment Geduld.
    </small>
  </p>
</div>
