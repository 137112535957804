/* tslint:disable */
/* eslint-disable */
export enum JofiStartYearForApprenticeshipEnum {
  EnumsStartYearForApprenticeshipImmediate = 'enums.startYearForApprenticeship.immediate',
  EnumsStartYearForApprenticeship202308 = 'enums.startYearForApprenticeship.2023-08',
  EnumsStartYearForApprenticeship202408 = 'enums.startYearForApprenticeship.2024-08',
  EnumsStartYearForApprenticeship202508 = 'enums.startYearForApprenticeship.2025-08',
  EnumsStartYearForApprenticeship202608 = 'enums.startYearForApprenticeship.2026-08',
  EnumsStartYearForApprenticeship202708 = 'enums.startYearForApprenticeship.2027-08',
  EnumsStartYearForApprenticeship202808 = 'enums.startYearForApprenticeship.2028-08',
  EnumsStartYearForApprenticeship202908 = 'enums.startYearForApprenticeship.2029-08'
}
