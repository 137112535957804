import { LoggerStoreService } from './logger-store.service';
import { LoggerType } from './logger.model';
import { environment } from '../../environments/environment';

export class LoggerService {
  constructor() {

  }

  /**
   * Creates a log string starting with the current timestamp and component name.
   * If no component (this) is handed over, it will just print the date.
   *
   * @param component
   * @param args
   * @constructor
   */
  static LOG(component: any, ...args: any[]): void {
    if (environment.isLoggingEnabled.logger) {
      const componentClassName =
        LoggerService.GET_COMPONENT_OR_CLASSNAME(component);
      LoggerStoreService.ADD(componentClassName, LoggerType.LOG, ...args);
      console.log(LoggerService.GET_LOG_STRING(componentClassName), ...args);
    }
  }

  /**
   * Creates a log string starting with the current timestamp and component name.
   * If no component (this) is handed over, it will just print the date.
   *
   * @param component
   * @param args
   * @constructor
   */
  static ERROR(component: any, ...args: any[]): void {
    if (environment.isLoggingEnabled.logger) {
      const componentClassName =
        LoggerService.GET_COMPONENT_OR_CLASSNAME(component);
      LoggerStoreService.ADD(componentClassName, LoggerType.ERROR, ...args);
      console.error(LoggerService.GET_LOG_STRING(componentClassName), ...args);
    }
  }

  /**
   * Creates a log string starting with the current timestamp and component name.
   * If no component (this) is handed over, it will just print the date.
   *
   * @param componentName
   * @constructor
   */
  static GET_LOG_STRING(componentName: string): string {
    let logString = '';
    const now = new Date();
    const hours = ('0' + now.getHours()).slice(-2);
    const minutes = ('0' + now.getMinutes()).slice(-2);
    const seconds = ('0' + now.getSeconds()).slice(-2);
    const milliseconds = ('00' + now.getSeconds()).slice(-3);
    logString +=
      hours + ':' + minutes + ':' + seconds + '.' + milliseconds + ' -';
    logString += ' ' + componentName + ' -';
    return logString;
  }

  /**
   * Just returns either the components/class' name or the component string itself.
   *
   * @param component
   * @constructor
   */
  static GET_COMPONENT_OR_CLASSNAME(component: any): string {
    if (
      component &&
      typeof component === 'object' &&
      component.constructor &&
      component.constructor.name
    ) {
      return component.constructor.name;
    } else {
      return component;
    }
  }
}
