<div *ngIf="company">
  <h1 class="text-2xl mb-4">{{ company.name }}</h1>

  <form>
    <div class="grid gap-6 mb-6 md:grid-cols-2">
      <div>
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.company' | translate }}
        </label>
        <input type="text" id="name"
               name="name"
               [(ngModel)]="company.name"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div>
        <label for="street" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.street' | translate }}
        </label>
        <input type="text" id="street"
               name="street"
               [(ngModel)]="company.street"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div>
        <label for="zipCode" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.zipCode' | translate }}
        </label>
        <input type="text" id="zipCode"
               name="zipCode"
               [(ngModel)]="company.zipCode"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div>
        <label for="city" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.city' | translate }}
        </label>
        <input type="text" id="city"
               name="city"
               [(ngModel)]="company.city"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div>
        <label for="website" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.website' | translate }}
        </label>
        <input type="text" id="website"
               name="website"
               [(ngModel)]="company.website"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div>
        <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.phone' | translate }}
        </label>
        <input type="text" id="phone"
               name="phone"
               [(ngModel)]="company.phone"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div>
        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.email' | translate }}
        </label>
        <input type="text" id="email"
               name="email"
               [(ngModel)]="company.email"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
    </div>
    <div class="grid gap-6 mb-6">
      <div>
        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.company.descriptionBenefits' | translate }}
        </label>
        <div [innerHTML]="company.descriptionBenefits"></div>
      </div>
      <div>
        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.company.descriptionWhatWeDo' | translate }}
        </label>
        <div [innerHTML]="company.descriptionWhatWeDo"></div>
      </div>
      <div>
        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.company.descriptionHiringProcess' | translate }}
        </label>
        <div [innerHTML]="company.descriptionHiringProcess"></div>
      </div>
    </div>

    <!-- Status -->
    <div class="mb-12">
      <h2 class="text-1xl font-bold">Status</h2>
      <div>
        <label class="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" [checked]="company.isBlocked" class="sr-only peer" name="isBlocked"
                 [(ngModel)]="company.isBlocked">
          <div
            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                {{ 'global.personal.isBlocked' | translate }}
              </span>
        </label>
      </div>
      <div class="flex items-center">
        Unternehmen freigeschaltet: {{ company.isConfirmed | booleanToTextKey | translate }}
        <div class="w-6 h-6 ml-1" *ngIf="company.isConfirmed">
          <svg class="text-green-400" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"></path>
          </svg>
        </div>
        <div class="w-6 h-6 ml-1" *ngIf="!company.isConfirmed">
          <svg class="text-red-600" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </div>
      </div>
    </div>

    <!-- Members -->
    <div class="grid gap-6 mb-12 md:grid-cols-1" *ngIf="company.members && company.members.length">
      <h2 class="text-1xl font-bold">Zugehörige Personen</h2>
      <div *ngFor="let member of company.members"
           class="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow grid gap-6 mb-2 md:grid-cols-2">
        <div>
          <h4 class="font-bold">{{ member.user?.firstName }} {{ member.user?.lastName }}</h4>
          <p>Status: {{ member.status ? member.status : 'OWNER' }}</p>
          <p>Auf Unternehmensseite anzeigen: {{ member.showInPublic | booleanToTextKey | translate }}</p>
          <p>E-Mail: {{ member.user?.email }}</p>
        </div>
        <div *ngIf="!member.status && !company.isConfirmed">
          <button type="submit"
                  [disabled]="!member.user?.isConfirmed"
                  (click)="confirmCompany(company.id, member.user)"
                  class="mr-2 disabled:opacity-50 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
            Firma freischalten und {{ member.user?.firstName }} {{ member.user?.lastName }} darüber informieren
          </button>
          <small class="block mt-1 text-red-600" *ngIf="!member.user?.isConfirmed">Firma kann nicht freigeschaltet
            werden,
            solange {{ member.user?.firstName }} {{ member.user?.lastName }} seine/ihre E-Mail Adresse nicht bestätigt
            hat. Bitte auch
            prüfen, ob das eine offizielle E-Mail Adresse von {{ company.name }} ist (bzw. die Domain stimmt).</small>
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      <button type="submit"
              (click)="save()"
              class="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
        Speichern
      </button>
      <button type="submit"
              [swal]="{ title: company.name + ' wirklich löschen?', showCancelButton: true }"
              (confirm)="delete()"
              class="mr-2 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
        Account löschen
      </button>
    </div>
  </form>
</div>
