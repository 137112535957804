import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, concatMap, retryWhen, share, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';
import { JofiAuthenticationService } from '../app/_generated/jofi-rest-api';
import { AppState } from '../app/state/app/app.state';
import { AppStateSetAuthentication } from '../app/state/app/app.actions';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
  private retryRequest = Symbol('reload');
  private refreshToken = this.store.select(AppState.refreshToken)
    .pipe(
      concatMap(refreshToken => this.jofiAuthenticationService
        .authenticationControllerRefreshToken({ body: { refreshToken } })
        .pipe(
          tap(newTokenResponse => {
            if (newTokenResponse.access_token && newTokenResponse.refresh_token) {
              this.store.dispatch(new AppStateSetAuthentication(newTokenResponse.access_token, newTokenResponse.refresh_token));
              throw this.retryRequest;
            }
          }),
          share(),
        ) as Observable<any>),
    );
  private errorBeforeRefreshTokenError: any;

  constructor(
    private store: Store,
    private jofiAuthenticationService: JofiAuthenticationService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request$ = new Observable<HttpRequest<any>>(observer => {
      observer.next(this.addToken(request));
      observer.complete();
    });

    // @ts-ignore
    return request$.pipe(
      switchMap(req => next.handle(req)),
      catchError((error: Error) => {
        if (error instanceof HttpErrorResponse) {
          // Catch refresh token error, which does a logout.
          if (error.url && error.url.indexOf(JofiAuthenticationService.AuthenticationControllerRefreshTokenPath) > -1) {
            this.store.dispatch(new StateClear());
            throw this.errorBeforeRefreshTokenError;
          }
          this.errorBeforeRefreshTokenError = error;
          switch (error.status) {
            case 401:
              return this.refreshToken;
            default:
              throw error;
          }
        } else {
          throw error;
        }
      }),
      retryWhen(err$ =>
        err$.pipe(
          tap(err => {
            if (err === this.retryRequest) {
              return;
            }
            throw err;
          }),
        ),
      ),
    );
  }

  /**
   * Adds the bearer token to the http request.
   *
   * @param req
   * @private
   */
  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.store.selectSnapshot(AppState.accessToken),
      },
    });
  }
}
