<div *ngIf="!(isLoggedIn$ | async)"
     class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
  <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate class="space-y-6">
    <div>
      <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {{ 'pages.login.email' | translate }}
      </label>
      <input type="text" name="username" id="username"
             formControlName="username"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
             placeholder="{{ 'pages.login.email' | translate }}" required>
      <small class="form-text text-red-600 block mb-4"
             *ngIf="loginForm.controls['username'].touched && loginForm.controls['username'].hasError('required')">
        {{ 'pages.login.email' | translate }} {{ 'global.ui.isRequired' | translate }}
      </small>
    </div>
    <div>
      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {{ 'pages.login.password' | translate }}
      </label>
      <input type="password" name="password" id="password"
             formControlName="password"
             placeholder="{{ 'pages.login.password' | translate }}"
             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
             required>
      <small class="form-text text-red-600 block mb-4"
             *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].hasError('required')">
        {{ 'pages.login.password' | translate }} {{ 'global.ui.isRequired' | translate }}
      </small>
    </div>
    <button type="submit"
            [disabled]="!loginForm.valid"
            class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      {{ 'pages.login.login' | translate }}
    </button>
  </form>
</div>
