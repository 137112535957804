<div>
  <h1 class="text-2xl mb-4">Lehrberuf erstellen</h1>

  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <form [formGroup]="newEntityForm" (ngSubmit)="save()" novalidate class="space-y-6">
      <div>
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Name
        </label>
        <input type="text" name="name" id="name"
               formControlName="name"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
               placeholder="Name" required>
        <small class="form-text text-red-600 block mb-4"
               *ngIf="newEntityForm.controls['name'].touched && newEntityForm.controls['name'].hasError('required')">
          Name {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <div>
        <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Beschreibung
        </label>
        <textarea name="description" id="description"
                  formControlName="description"
                  placeholder="Beschreibung"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"></textarea>
        <small class="form-text text-red-600 block mb-4"
               *ngIf="newEntityForm.controls['description'].touched && newEntityForm.controls['description'].hasError('required')">
          Beschreibung {{ 'global.ui.isRequired' | translate }}
        </small>
      </div>
      <button type="submit"
              [disabled]="!newEntityForm.valid"
              class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        {{ 'global.ui.save' | translate }}
      </button>
    </form>
  </div>
</div>
