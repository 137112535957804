import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  JofiAdminInterestGroupsService,
  JofiAdminInterestsService,
  JofiInterestGroup,
  JofiUserTypeEnum,
} from '../../_generated/jofi-rest-api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'jofi-interests-new-page',
  templateUrl: './interests-new.page.html',
  styleUrls: ['interests-new.page.scss'],
})
export class InterestsNewPage implements OnDestroy {
  public newEntityForm: FormGroup;
  public interestGroups: JofiInterestGroup[] = [];

  constructor(
    private readonly store: Store,
    private readonly jofiAdminInterestsService: JofiAdminInterestsService,
    private readonly jofiAdminInterestGroupsService: JofiAdminInterestGroupsService,
    private readonly router: Router,
  ) {
    this.newEntityForm = new FormGroup({
      interestGroupId: new FormControl('', [Validators.required]),
      textForStudent: new FormControl('', [Validators.required]),
      textForCompany: new FormControl('', [Validators.required]),
      description: new FormControl('', []),
    });
  }

  ngOnInit() {
    this.initializeInterestGroups();
  }

  ngOnDestroy(): void {
  }

  public setInterestGroupId(event: Event) {
    const interestGroupId = (event.target as HTMLSelectElement).value;
    this.newEntityForm.patchValue({ interestGroupId: interestGroupId });
  }

  public save() {
    if (this.newEntityForm.valid) {
      this.jofiAdminInterestsService.interestsAdminControllerCreate({ body: this.newEntityForm.value })
        .subscribe({
          next: () => {
            swal.fire('Gespeichert!', '', 'success');
            this.router.navigateByUrl('/interests').then();
          },
        });
    }
  }

  protected readonly JofiUserTypeEnum = JofiUserTypeEnum;

  private initializeInterestGroups() {
    this.jofiAdminInterestGroupsService.interestGroupsAdminControllerFindAll().subscribe({
      next: interestGroups => {
        if (interestGroups && interestGroups.length) {
          this.interestGroups = interestGroups;
          this.newEntityForm.patchValue({ interestGroupId: interestGroups[0].id });
        }
      },
    });
  }
}
