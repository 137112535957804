import { GLOBALS } from './global.environment';

export const environment = GLOBALS;

// Overwrite settings for this environment.
environment.name = 'test';
environment.apiConfiguration = {
  baseUrl: 'https://test-api.jofi.ch',
  apiSecret: '', // Must never be put here since it will be exposed then!
  apiKey: 'd63dc2c66c7650dc3713bd7b10999dfd89d12662',
};
environment.isLoggingEnabled.ngxs = false;
environment.isLoggingEnabled.logger = false;
environment.production = false;
