<div *ngIf="user">
  <h1 class="text-2xl mb-4">{{ user.id }}</h1>

  <form>
    <div class="grid gap-6 mb-6 md:grid-cols-2">
      <div>
        <label for="firstName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.firstName' | translate }}
        </label>
        <input type="text" id="firstName"
               name="firstName"
               [(ngModel)]="user.firstName"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div>
        <label for="firstName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.lastName' | translate }}
        </label>
        <input type="text" id="lastName"
               name="lastName"
               [(ngModel)]="user.lastName"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div>
        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.email' | translate }}
        </label>
        <input type="text" id="email"
               name="email"
               [(ngModel)]="user.email"
               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div>
        <label for="userType" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {{ 'global.personal.userType' | translate }}
        </label>
        <select id="userType"
                (change)="setUserType($event)"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option [selected]="user.userType === jofiUserTypeEnum.Student"
                  [value]="jofiUserTypeEnum.Student">
            {{ 'enums.userType.' + jofiUserTypeEnum.Student | translate }}
          </option>
          <option [selected]="user.userType === jofiUserTypeEnum.Recruiter"
                  [value]="jofiUserTypeEnum.Recruiter">
            {{ 'enums.userType.' + jofiUserTypeEnum.Recruiter | translate }}
          </option>
          <option [selected]="user.userType === jofiUserTypeEnum.Admin"
                  [disabled]="true"
                  [value]="jofiUserTypeEnum.Admin">
            {{ 'enums.userType.' + jofiUserTypeEnum.Admin | translate }}
          </option>
        </select>
      </div>
      <div>
        <label class="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" [checked]="user.isBlocked" class="sr-only peer" name="isBlocked"
                 [(ngModel)]="user.isBlocked">
          <div
            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            {{ 'global.personal.isBlocked' | translate }}
          </span>
        </label>
      </div>
      <div class="flex items-center">
        E-Mail Adresse bestätigt: {{ user.isConfirmed | booleanToTextKey | translate }}
        <div class="w-6 h-6 ml-1" *ngIf="user.isConfirmed">
          <svg class="text-green-400" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"></path>
          </svg>
        </div>
        <div class="w-6 h-6 ml-1" *ngIf="!user.isConfirmed">
          <svg class="text-red-600" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </div>
        <button type="button"
                (click)="sendVerificationMail()"
                class="py-2.5 px-5 ml-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200">
          Verifizierungs-E-Mail erneut senden
        </button>
      </div>
    </div>
    <!-- Company -->
    <div class="grid gap-6 mb-6 md:grid-cols-1" *ngIf="user.companyMemberships && user.companyMemberships.length">
      <h2 class="text-1xl font-bold">Zugehörige Firmen</h2>
      <div *ngFor="let companyMembership of user.companyMemberships"
           class="block w-full p-6 bg-white border border-gray-200 rounded-lg shadow grid gap-6 mb-6 md:grid-cols-2">
        <div>
          <h4 class="font-bold">{{ companyMembership.company?.name }}</h4>
          <p>
            {{ companyMembership.company?.street }}<br>
            {{ companyMembership.company?.zipCode }} {{ companyMembership.company?.city }}
          </p>
        </div>
        <div>
          <div class="mb-4">
            <a class="block" href="{{ companyMembership.company?.website }}" target="_blank">
              {{ companyMembership.company?.website }}
            </a>
            <a class="block" href="mailto:{{ companyMembership.company?.email }}" target="_blank">
              {{ companyMembership.company?.email }}
            </a>
            <a class="block" href="tel:{{ companyMembership.company?.phone }}" target="_blank">
              {{ companyMembership.company?.phone }}
            </a>
          </div>
          <div class="flex items-center">
            Freigeschaltet: {{ companyMembership.company?.isConfirmed | booleanToTextKey | translate }}
            <div class="w-6 h-6 ml-1" *ngIf="companyMembership.company?.isConfirmed">
              <svg class="text-green-400" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"></path>
              </svg>
            </div>
            <div class="w-6 h-6 ml-1" *ngIf="!companyMembership.company?.isConfirmed">
              <svg class="text-red-600" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
            </div>
          </div>
          <p>
            Blockiert: {{ companyMembership.company?.isBlocked | booleanToTextKey | translate }}
          </p>
        </div>
        <div>
          <button type="submit"
                  [disabled]="!user.isConfirmed"
                  (click)="confirmCompany(companyMembership.company?.id)"
                  class="mr-2 disabled:opacity-50 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
            Firma freischalten und {{ user.firstName }} {{ user.lastName }} darüber informieren
          </button>
          <small class="block mt-1 text-red-600" *ngIf="!user.isConfirmed">Firma kann nicht freigeschaltet werden,
            solange {{ user.firstName }} {{ user.lastName }} seine/ihre E-Mail Adresse nicht bestätigt hat. Bitte auch
            prüfen, ob das eine offizielle E-Mail Adresse von {{ companyMembership.company?.name }} ist (bzw. die Domain
            stimmt).</small>
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      <button type="submit"
              (click)="save()"
              class="mr-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
        Speichern
      </button>
      <button type="submit"
              [swal]="{ title: 'Benutzer:in wirklich löschen?', showCancelButton: true }"
              (confirm)="delete()"
              class="mr-2 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">
        Account löschen
      </button>
    </div>
  </form>

</div>
