/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JofiCreateProfessionDto } from '../models/jofi-create-profession-dto';
import { JofiProfession } from '../models/jofi-profession';
import { JofiUpdateProfessionDto } from '../models/jofi-update-profession-dto';

@Injectable({
  providedIn: 'root',
})
export class JofiAdminProfessionsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation professionsAdminControllerFindAll
   */
  static readonly ProfessionsAdminControllerFindAllPath = '/v1/admin/professions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `professionsAdminControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  professionsAdminControllerFindAll$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<JofiProfession>>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminProfessionsService.ProfessionsAdminControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<JofiProfession>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `professionsAdminControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  professionsAdminControllerFindAll(params?: {
    context?: HttpContext
  }
): Observable<Array<JofiProfession>> {

    return this.professionsAdminControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<JofiProfession>>) => r.body as Array<JofiProfession>)
    );
  }

  /**
   * Path part for operation professionsAdminControllerCreate
   */
  static readonly ProfessionsAdminControllerCreatePath = '/v1/admin/professions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `professionsAdminControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  professionsAdminControllerCreate$Response(params: {
    context?: HttpContext
    body: JofiCreateProfessionDto
  }
): Observable<StrictHttpResponse<JofiProfession>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminProfessionsService.ProfessionsAdminControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiProfession>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `professionsAdminControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  professionsAdminControllerCreate(params: {
    context?: HttpContext
    body: JofiCreateProfessionDto
  }
): Observable<JofiProfession> {

    return this.professionsAdminControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiProfession>) => r.body as JofiProfession)
    );
  }

  /**
   * Path part for operation professionsAdminControllerFindById
   */
  static readonly ProfessionsAdminControllerFindByIdPath = '/v1/admin/professions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `professionsAdminControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  professionsAdminControllerFindById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiProfession>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminProfessionsService.ProfessionsAdminControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiProfession>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `professionsAdminControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  professionsAdminControllerFindById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<JofiProfession> {

    return this.professionsAdminControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<JofiProfession>) => r.body as JofiProfession)
    );
  }

  /**
   * Path part for operation professionsAdminControllerUpdate
   */
  static readonly ProfessionsAdminControllerUpdatePath = '/v1/admin/professions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `professionsAdminControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  professionsAdminControllerUpdate$Response(params: {
    id: string;
    context?: HttpContext
    body: JofiUpdateProfessionDto
  }
): Observable<StrictHttpResponse<JofiProfession>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminProfessionsService.ProfessionsAdminControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiProfession>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `professionsAdminControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  professionsAdminControllerUpdate(params: {
    id: string;
    context?: HttpContext
    body: JofiUpdateProfessionDto
  }
): Observable<JofiProfession> {

    return this.professionsAdminControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiProfession>) => r.body as JofiProfession)
    );
  }

}
