import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { JofiAdminProfessionsService, JofiProfession } from '../../_generated/jofi-rest-api';
import swal from 'sweetalert2';

@Component({
  selector: 'jofi-professions-page',
  templateUrl: './professions.page.html',
  styleUrls: ['professions.page.scss'],
})
export class ProfessionsPage implements OnDestroy {
  public professions: JofiProfession[] = [];

  constructor(
    private readonly store: Store,
    private readonly jofiAdminProfessionsService: JofiAdminProfessionsService,
  ) {
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy(): void {
  }

  public update(entity: JofiProfession) {
    if (entity && entity.id) {
      this.jofiAdminProfessionsService.professionsAdminControllerUpdate({
        id: entity.id,
        body: entity,
      }).subscribe({
        next: () => {
          swal.fire('Gespeichert!', '', 'success');
        },
      });
    }
  }

  private load() {
    this.jofiAdminProfessionsService.professionsAdminControllerFindAll().subscribe({
      next: entities => {
        this.professions = entities;
      },
    });
  }
}
