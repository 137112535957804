/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JofiCreateStudentProfilePrivateDto } from '../models/jofi-create-student-profile-private-dto';
import { JofiCreateStudentProfilePublicDto } from '../models/jofi-create-student-profile-public-dto';
import { JofiStudentProfileDto } from '../models/jofi-student-profile-dto';
import { JofiStudentProfilePrivate } from '../models/jofi-student-profile-private';
import { JofiStudentProfilePublic } from '../models/jofi-student-profile-public';
import { JofiUpdateStudentProfilePrivateDto } from '../models/jofi-update-student-profile-private-dto';
import { JofiUpdateStudentProfilePublicDto } from '../models/jofi-update-student-profile-public-dto';

@Injectable({
  providedIn: 'root',
})
export class JofiContentManagementStudentProfilesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation studentProfilesControllerFindPrivate
   */
  static readonly StudentProfilesControllerFindPrivatePath = '/v1/content-management/student-profiles/private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `studentProfilesControllerFindPrivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  studentProfilesControllerFindPrivate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiStudentProfilePrivate>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementStudentProfilesService.StudentProfilesControllerFindPrivatePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiStudentProfilePrivate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `studentProfilesControllerFindPrivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  studentProfilesControllerFindPrivate(params?: {
    context?: HttpContext
  }
): Observable<JofiStudentProfilePrivate> {

    return this.studentProfilesControllerFindPrivate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiStudentProfilePrivate>) => r.body as JofiStudentProfilePrivate)
    );
  }

  /**
   * Path part for operation studentProfilesControllerCreatePrivate
   */
  static readonly StudentProfilesControllerCreatePrivatePath = '/v1/content-management/student-profiles/private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `studentProfilesControllerCreatePrivate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  studentProfilesControllerCreatePrivate$Response(params: {
    context?: HttpContext
    body: JofiCreateStudentProfilePrivateDto
  }
): Observable<StrictHttpResponse<JofiStudentProfilePrivate>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementStudentProfilesService.StudentProfilesControllerCreatePrivatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiStudentProfilePrivate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `studentProfilesControllerCreatePrivate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  studentProfilesControllerCreatePrivate(params: {
    context?: HttpContext
    body: JofiCreateStudentProfilePrivateDto
  }
): Observable<JofiStudentProfilePrivate> {

    return this.studentProfilesControllerCreatePrivate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiStudentProfilePrivate>) => r.body as JofiStudentProfilePrivate)
    );
  }

  /**
   * Path part for operation studentProfilesControllerUpdatePrivate
   */
  static readonly StudentProfilesControllerUpdatePrivatePath = '/v1/content-management/student-profiles/private';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `studentProfilesControllerUpdatePrivate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  studentProfilesControllerUpdatePrivate$Response(params: {
    context?: HttpContext
    body: JofiUpdateStudentProfilePrivateDto
  }
): Observable<StrictHttpResponse<JofiStudentProfilePrivate>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementStudentProfilesService.StudentProfilesControllerUpdatePrivatePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiStudentProfilePrivate>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `studentProfilesControllerUpdatePrivate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  studentProfilesControllerUpdatePrivate(params: {
    context?: HttpContext
    body: JofiUpdateStudentProfilePrivateDto
  }
): Observable<JofiStudentProfilePrivate> {

    return this.studentProfilesControllerUpdatePrivate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiStudentProfilePrivate>) => r.body as JofiStudentProfilePrivate)
    );
  }

  /**
   * Path part for operation studentProfilesControllerFindPublic
   */
  static readonly StudentProfilesControllerFindPublicPath = '/v1/content-management/student-profiles/public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `studentProfilesControllerFindPublic()` instead.
   *
   * This method doesn't expect any request body.
   */
  studentProfilesControllerFindPublic$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiStudentProfilePublic>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementStudentProfilesService.StudentProfilesControllerFindPublicPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiStudentProfilePublic>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `studentProfilesControllerFindPublic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  studentProfilesControllerFindPublic(params?: {
    context?: HttpContext
  }
): Observable<JofiStudentProfilePublic> {

    return this.studentProfilesControllerFindPublic$Response(params).pipe(
      map((r: StrictHttpResponse<JofiStudentProfilePublic>) => r.body as JofiStudentProfilePublic)
    );
  }

  /**
   * Path part for operation studentProfilesControllerCreatePublic
   */
  static readonly StudentProfilesControllerCreatePublicPath = '/v1/content-management/student-profiles/public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `studentProfilesControllerCreatePublic()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  studentProfilesControllerCreatePublic$Response(params: {
    context?: HttpContext
    body: JofiCreateStudentProfilePublicDto
  }
): Observable<StrictHttpResponse<JofiStudentProfilePublic>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementStudentProfilesService.StudentProfilesControllerCreatePublicPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiStudentProfilePublic>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `studentProfilesControllerCreatePublic$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  studentProfilesControllerCreatePublic(params: {
    context?: HttpContext
    body: JofiCreateStudentProfilePublicDto
  }
): Observable<JofiStudentProfilePublic> {

    return this.studentProfilesControllerCreatePublic$Response(params).pipe(
      map((r: StrictHttpResponse<JofiStudentProfilePublic>) => r.body as JofiStudentProfilePublic)
    );
  }

  /**
   * Path part for operation studentProfilesControllerUpdatePublic
   */
  static readonly StudentProfilesControllerUpdatePublicPath = '/v1/content-management/student-profiles/public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `studentProfilesControllerUpdatePublic()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  studentProfilesControllerUpdatePublic$Response(params: {
    context?: HttpContext
    body: JofiUpdateStudentProfilePublicDto
  }
): Observable<StrictHttpResponse<JofiStudentProfilePublic>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementStudentProfilesService.StudentProfilesControllerUpdatePublicPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiStudentProfilePublic>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `studentProfilesControllerUpdatePublic$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  studentProfilesControllerUpdatePublic(params: {
    context?: HttpContext
    body: JofiUpdateStudentProfilePublicDto
  }
): Observable<JofiStudentProfilePublic> {

    return this.studentProfilesControllerUpdatePublic$Response(params).pipe(
      map((r: StrictHttpResponse<JofiStudentProfilePublic>) => r.body as JofiStudentProfilePublic)
    );
  }

  /**
   * Path part for operation studentProfilesControllerFindAllPaginated
   */
  static readonly StudentProfilesControllerFindAllPaginatedPath = '/v1/content-management/student-profiles/{apprenticeshipId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `studentProfilesControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  studentProfilesControllerFindAllPaginated$Response(params: {
    apprenticeshipId: string;
    filterByCantons: string;
    filterByStudentCantons: string;
    'filter.expectedDegree'?: any;
    'filter.startYearForApprenticeship'?: any;
    'filter.openForOtherProfessions'?: any;
    'filter.matches.addedAsFavoriteAt'?: any;
    'filter.matches.viewedAt'?: any;
    'filter.openForCantons.id'?: any;
    sortBy?: any;
    search?: any;
    limit?: any;
    page?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<JofiStudentProfileDto>>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementStudentProfilesService.StudentProfilesControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.path('apprenticeshipId', params.apprenticeshipId, {});
      rb.query('filterByCantons', params.filterByCantons, {});
      rb.query('filterByStudentCantons', params.filterByStudentCantons, {});
      rb.query('filter.expectedDegree', params['filter.expectedDegree'], {});
      rb.query('filter.startYearForApprenticeship', params['filter.startYearForApprenticeship'], {});
      rb.query('filter.openForOtherProfessions', params['filter.openForOtherProfessions'], {});
      rb.query('filter.matches.addedAsFavoriteAt', params['filter.matches.addedAsFavoriteAt'], {});
      rb.query('filter.matches.viewedAt', params['filter.matches.viewedAt'], {});
      rb.query('filter.openForCantons.id', params['filter.openForCantons.id'], {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('search', params.search, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<JofiStudentProfileDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `studentProfilesControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  studentProfilesControllerFindAllPaginated(params: {
    apprenticeshipId: string;
    filterByCantons: string;
    filterByStudentCantons: string;
    'filter.expectedDegree'?: any;
    'filter.startYearForApprenticeship'?: any;
    'filter.openForOtherProfessions'?: any;
    'filter.matches.addedAsFavoriteAt'?: any;
    'filter.matches.viewedAt'?: any;
    'filter.openForCantons.id'?: any;
    sortBy?: any;
    search?: any;
    limit?: any;
    page?: any;
    context?: HttpContext
  }
): Observable<Array<JofiStudentProfileDto>> {

    return this.studentProfilesControllerFindAllPaginated$Response(params).pipe(
      map((r: StrictHttpResponse<Array<JofiStudentProfileDto>>) => r.body as Array<JofiStudentProfileDto>)
    );
  }

  /**
   * Path part for operation studentProfilesControllerFindAllWithRequestsByApprenticeshipId
   */
  static readonly StudentProfilesControllerFindAllWithRequestsByApprenticeshipIdPath = '/v1/content-management/student-profiles/requests/{apprenticeshipId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `studentProfilesControllerFindAllWithRequestsByApprenticeshipId()` instead.
   *
   * This method doesn't expect any request body.
   */
  studentProfilesControllerFindAllWithRequestsByApprenticeshipId$Response(params: {
    apprenticeshipId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<JofiStudentProfileDto>>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementStudentProfilesService.StudentProfilesControllerFindAllWithRequestsByApprenticeshipIdPath, 'get');
    if (params) {
      rb.path('apprenticeshipId', params.apprenticeshipId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<JofiStudentProfileDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `studentProfilesControllerFindAllWithRequestsByApprenticeshipId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  studentProfilesControllerFindAllWithRequestsByApprenticeshipId(params: {
    apprenticeshipId: string;
    context?: HttpContext
  }
): Observable<Array<JofiStudentProfileDto>> {

    return this.studentProfilesControllerFindAllWithRequestsByApprenticeshipId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<JofiStudentProfileDto>>) => r.body as Array<JofiStudentProfileDto>)
    );
  }

  /**
   * Path part for operation studentProfilesControllerFindOneProfileDto
   */
  static readonly StudentProfilesControllerFindOneProfileDtoPath = '/v1/content-management/student-profiles/{apprenticeshipId}/{studentProfilePublicId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `studentProfilesControllerFindOneProfileDto()` instead.
   *
   * This method doesn't expect any request body.
   */
  studentProfilesControllerFindOneProfileDto$Response(params: {
    apprenticeshipId: string;
    studentProfilePublicId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiStudentProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementStudentProfilesService.StudentProfilesControllerFindOneProfileDtoPath, 'get');
    if (params) {
      rb.path('apprenticeshipId', params.apprenticeshipId, {});
      rb.path('studentProfilePublicId', params.studentProfilePublicId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiStudentProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `studentProfilesControllerFindOneProfileDto$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  studentProfilesControllerFindOneProfileDto(params: {
    apprenticeshipId: string;
    studentProfilePublicId: string;
    context?: HttpContext
  }
): Observable<JofiStudentProfileDto> {

    return this.studentProfilesControllerFindOneProfileDto$Response(params).pipe(
      map((r: StrictHttpResponse<JofiStudentProfileDto>) => r.body as JofiStudentProfileDto)
    );
  }

}
