/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JofiCreateInterestDto } from '../models/jofi-create-interest-dto';
import { JofiInterest } from '../models/jofi-interest';
import { JofiUpdateInterestDto } from '../models/jofi-update-interest-dto';

@Injectable({
  providedIn: 'root',
})
export class JofiAdminInterestsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation interestsAdminControllerFindAll
   */
  static readonly InterestsAdminControllerFindAllPath = '/v1/admin/interests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `interestsAdminControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  interestsAdminControllerFindAll$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<JofiInterest>>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminInterestsService.InterestsAdminControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<JofiInterest>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `interestsAdminControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  interestsAdminControllerFindAll(params?: {
    context?: HttpContext
  }
): Observable<Array<JofiInterest>> {

    return this.interestsAdminControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<JofiInterest>>) => r.body as Array<JofiInterest>)
    );
  }

  /**
   * Path part for operation interestsAdminControllerCreate
   */
  static readonly InterestsAdminControllerCreatePath = '/v1/admin/interests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `interestsAdminControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  interestsAdminControllerCreate$Response(params: {
    context?: HttpContext
    body: JofiCreateInterestDto
  }
): Observable<StrictHttpResponse<JofiInterest>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminInterestsService.InterestsAdminControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiInterest>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `interestsAdminControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  interestsAdminControllerCreate(params: {
    context?: HttpContext
    body: JofiCreateInterestDto
  }
): Observable<JofiInterest> {

    return this.interestsAdminControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiInterest>) => r.body as JofiInterest)
    );
  }

  /**
   * Path part for operation interestsAdminControllerFindById
   */
  static readonly InterestsAdminControllerFindByIdPath = '/v1/admin/interests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `interestsAdminControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  interestsAdminControllerFindById$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiInterest>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminInterestsService.InterestsAdminControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiInterest>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `interestsAdminControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  interestsAdminControllerFindById(params: {
    id: string;
    context?: HttpContext
  }
): Observable<JofiInterest> {

    return this.interestsAdminControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<JofiInterest>) => r.body as JofiInterest)
    );
  }

  /**
   * Path part for operation interestsAdminControllerUpdate
   */
  static readonly InterestsAdminControllerUpdatePath = '/v1/admin/interests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `interestsAdminControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  interestsAdminControllerUpdate$Response(params: {
    id: string;
    context?: HttpContext
    body: JofiUpdateInterestDto
  }
): Observable<StrictHttpResponse<JofiInterest>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAdminInterestsService.InterestsAdminControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiInterest>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `interestsAdminControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  interestsAdminControllerUpdate(params: {
    id: string;
    context?: HttpContext
    body: JofiUpdateInterestDto
  }
): Observable<JofiInterest> {

    return this.interestsAdminControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiInterest>) => r.body as JofiInterest)
    );
  }

}
