import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { JofiAdminCompaniesService, JofiCompany, JofiUser } from '../../_generated/jofi-rest-api';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { getApiErrorMessageFromResponse } from '../../../_common/api-error.handler';

@Component({
  selector: 'jofi-companies-detail-page',
  templateUrl: './companies-detail.page.html',
  styleUrls: ['companies-detail.page.scss'],
})
export class CompaniesDetailPage implements OnDestroy {
  public company: JofiCompany | undefined;

  constructor(
    private readonly store: Store,
    private readonly jofiAdminCompaniesService: JofiAdminCompaniesService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params && params['id']) {
        this.load(params['id']);
      }
    });
  }

  ngOnDestroy(): void {
  }

  public save() {
    if (this.company && this.company.id) {
      this.jofiAdminCompaniesService.companiesAdminControllerUpdate({
        id: this.company.id,
        body: this.company,
      }).subscribe({
        next: () => {
          swal.fire('Gespeichert!', '', 'success');
        },
      });
    }
  }

  public delete() {
    if (this.company && this.company?.id) {
      this.jofiAdminCompaniesService.companiesAdminControllerDelete({
        id: this.company?.id,
      }).subscribe({
        next: () => {
          swal.fire('Unternehmen gelöscht!', '', 'success');
          this.router.navigateByUrl('/companies').then();
        },
        error: apiException => {
          swal.fire('Fehler', getApiErrorMessageFromResponse(apiException), 'error');
        },
      });
    }
  }

  public confirmCompany(companyId: string | undefined, user: JofiUser | undefined) {
    if (companyId && user) {
      this.jofiAdminCompaniesService.companiesAdminControllerConfirm({
        companyId: companyId,
        userId: user.id,
      }).subscribe({
        next: () => {
          swal.fire('Freigeschaltet', `Das Unternehmen wurde freigeschaltet und ist nun sichtbar auf Jofi. ${user?.firstName} ${user?.lastName} wurde darüber per E-Mail informiert.`, 'success').then(result => {
            window.location.reload();
          });
        },
      });
    }
  }

  private load(id: string) {
    this.jofiAdminCompaniesService.companiesAdminControllerFindById({
      id: id,
    }).subscribe({
      next: company => {
        this.company = company;
      },
    });
  }
}
