import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from '@ngxs/store';
import { AppStateModel } from './app.state.model';
import { AppStateSetAuthentication, AppStateSetProfileImage, AppStateSetUser } from './app.actions';
import { JofiAuthenticationService, JofiUpload, JofiUser, JofiUserTypeEnum } from '../../_generated/jofi-rest-api';
import { Router } from '@angular/router';
import { StateClear } from 'ngxs-reset-plugin';

export const STATE_NAME = 'JOFI__AdminState';
const DEFAULT_STATE: AppStateModel = {
  profileImage: undefined,
  user: undefined as any,
  authentication: {
    accessToken: '',
    refreshToken: '',
  },
};

@State<AppStateModel>({
  name: STATE_NAME,
  defaults: DEFAULT_STATE,
})
@Injectable()
export class AppState implements NgxsAfterBootstrap {
  constructor(
    private jofiAuthenticationService: JofiAuthenticationService,
    private router: Router,
  ) {
  }

  @Selector()
  static user(state: AppStateModel): JofiUser {
    return state.user;
  }

  @Selector()
  static profileImage(state: AppStateModel): JofiUpload | undefined {
    return state.profileImage;
  }

  @Selector()
  static userType(state: AppStateModel): JofiUserTypeEnum {
    return state.user.userType;
  }

  @Selector()
  static isLoggedIn(state: AppStateModel): boolean {
    return !!(state.authentication && state.authentication.accessToken);
  }

  @Selector()
  static refreshToken(state: AppStateModel): string {
    return state.authentication.refreshToken;
  }

  @Selector()
  static accessToken(state: AppStateModel): string {
    return state.authentication.accessToken;
  }

  @Action(AppStateSetAuthentication)
  appStateSetAuthentication(ctx: StateContext<AppStateModel>, action: AppStateSetAuthentication) {
    ctx.patchState({
      authentication: {
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      },
    });
    return this.loadAndSetProfile(ctx);
  }

  @Action(AppStateSetUser)
  appStateSetUser(ctx: StateContext<AppStateModel>, action: AppStateSetUser) {
    ctx.patchState({
      user: action.user,
    });
  }

  @Action(AppStateSetProfileImage)
  appStateSetProfileImage(ctx: StateContext<AppStateModel>, action: AppStateSetProfileImage) {
    ctx.patchState({
      profileImage: action.profileImage,
    });
  }

  ngxsAfterBootstrap(ctx: StateContext<AppStateModel>) {
    this.setDefaults(ctx);
    this.loadAndSetProfile(ctx);
  }

  private loadAndSetProfile(ctx: StateContext<AppStateModel>) {
    if (ctx.getState().authentication && ctx.getState().authentication.accessToken) {
      this.jofiAuthenticationService.authenticationControllerProfile().subscribe({
        next: profile => {
          if (profile && profile.user && profile.userType === JofiUserTypeEnum.Admin) {
            ctx.dispatch(new AppStateSetUser(profile.user));
            try {
              ctx.dispatch(new AppStateSetProfileImage(profile.profileImage));
            } catch (noProfileImageException) {
              ctx.dispatch(new AppStateSetProfileImage(undefined));
            }
          } else {
            ctx.dispatch(new StateClear());
          }
        },
      });
    }
  }

  private setDefaults(ctx: StateContext<AppStateModel>) {
  }
}
