import { Component } from '@angular/core';
import { JofiAdminCompaniesService, JofiAuthenticationService, JofiCompany } from './_generated/jofi-rest-api';
import { Paginated } from 'nestjs-paginate';
import { Store } from '@ngxs/store';
import { AppState } from './state/app/app.state';
import swal from 'sweetalert2';
import { StateClear } from 'ngxs-reset-plugin';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isLoggedIn$ = this.store.select(AppState.isLoggedIn);
  public amountOfUnverifiedCompanies: number = 0;

  constructor(
    private readonly store: Store,
    private readonly jofiAdminCompaniesService: JofiAdminCompaniesService,
    private readonly jofiAuthenticationService: JofiAuthenticationService,
    private readonly router: Router,
  ) {
  }

  ngOnInit() {
    this.isLoggedIn$.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.initialize();
      }
    });
  }

  public logout() {
    this.jofiAuthenticationService.authenticationControllerLogout().subscribe({
      complete: () => {
        this.store.dispatch(new StateClear());
        this.router.navigateByUrl('/login').then();
        swal.fire('Bis bald!', '', 'success');
      },
    });
  }

  private initialize() {
    this.jofiAdminCompaniesService.companiesAdminControllerFindAllPaginated({
      'filter.isConfirmed': '$eq:false',
    }).subscribe({
      next: (companies: any) => {
        this.amountOfUnverifiedCompanies = (companies as Paginated<JofiCompany>).meta.totalItems;
      },
    });
  }
}
