import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { AppStateSetAuthentication, AppStateSetUser } from '../../state/app/app.actions';
import { Subscription, take } from 'rxjs';
import { AppState } from '../../state/app/app.state';
import swal from 'sweetalert2';
import { JofiAuthenticationService } from '../../_generated/jofi-rest-api';
import { Router } from '@angular/router';

@Component({
  selector: 'jofi-login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  public loginForm: FormGroup;
  public isLoggedIn$ = this.store.select(AppState.isLoggedIn);
  private loginSubscription$: Subscription | undefined;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly jofiAuthenticationService: JofiAuthenticationService,
    private readonly actions: Actions,
  ) {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    if (this.loginSubscription$) {
      this.loginSubscription$.unsubscribe();
    }
  }

  async login() {
    if (this.loginForm.valid) {
      this.loginSubscription$ = this.jofiAuthenticationService.authenticationControllerLogin({
        body: {
          username: this.loginForm.value.username,
          password: this.loginForm.value.password,
        },
      }).subscribe({
        next: async value => {
          await this.store.dispatch(new AppStateSetAuthentication(value.access_token, value.refresh_token));
          swal.fire('Willkommen!', 'Sie wurden erfolgreich eingeloggt.', 'success');
          this.actions.pipe(
            ofActionCompleted(AppStateSetUser),
            take(1),
          ).subscribe(() => {
            this.router.navigateByUrl('/dashboard').then();
          });
        },
        error: loginException => {
          swal.fire('Fehler', loginException, 'error');
        },
      });
    }
  }
}
