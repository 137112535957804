/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JofiAuthDto } from '../models/jofi-auth-dto';
import { JofiPasswordResetDto } from '../models/jofi-password-reset-dto';
import { JofiPasswordResetRequestDto } from '../models/jofi-password-reset-request-dto';
import { JofiProfileDto } from '../models/jofi-profile-dto';
import { JofiRefreshtokenDto } from '../models/jofi-refreshtoken-dto';
import { JofiRegisterDto } from '../models/jofi-register-dto';
import { JofiSuccessDto } from '../models/jofi-success-dto';
import { JofiTokenResponseDto } from '../models/jofi-token-response-dto';
import { JofiUpdateUserDto } from '../models/jofi-update-user-dto';
import { JofiVerifyDto } from '../models/jofi-verify-dto';

@Injectable({
  providedIn: 'root',
})
export class JofiAuthenticationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation authenticationControllerLogin
   */
  static readonly AuthenticationControllerLoginPath = '/v1/authentication/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogin$Response(params: {
    context?: HttpContext
    body: JofiAuthDto
  }
): Observable<StrictHttpResponse<JofiTokenResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAuthenticationService.AuthenticationControllerLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogin(params: {
    context?: HttpContext
    body: JofiAuthDto
  }
): Observable<JofiTokenResponseDto> {

    return this.authenticationControllerLogin$Response(params).pipe(
      map((r: StrictHttpResponse<JofiTokenResponseDto>) => r.body as JofiTokenResponseDto)
    );
  }

  /**
   * Path part for operation authenticationControllerVerify
   */
  static readonly AuthenticationControllerVerifyPath = '/v1/authentication/verify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerVerify()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerVerify$Response(params: {
    context?: HttpContext
    body: JofiVerifyDto
  }
): Observable<StrictHttpResponse<JofiTokenResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAuthenticationService.AuthenticationControllerVerifyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerVerify$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerVerify(params: {
    context?: HttpContext
    body: JofiVerifyDto
  }
): Observable<JofiTokenResponseDto> {

    return this.authenticationControllerVerify$Response(params).pipe(
      map((r: StrictHttpResponse<JofiTokenResponseDto>) => r.body as JofiTokenResponseDto)
    );
  }

  /**
   * Path part for operation authenticationControllerRegister
   */
  static readonly AuthenticationControllerRegisterPath = '/v1/authentication/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRegister$Response(params: {
    context?: HttpContext
    body: JofiRegisterDto
  }
): Observable<StrictHttpResponse<JofiSuccessDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAuthenticationService.AuthenticationControllerRegisterPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiSuccessDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRegister(params: {
    context?: HttpContext
    body: JofiRegisterDto
  }
): Observable<JofiSuccessDto> {

    return this.authenticationControllerRegister$Response(params).pipe(
      map((r: StrictHttpResponse<JofiSuccessDto>) => r.body as JofiSuccessDto)
    );
  }

  /**
   * Path part for operation authenticationControllerPasswordResetRequest
   */
  static readonly AuthenticationControllerPasswordResetRequestPath = '/v1/authentication/password-reset-request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerPasswordResetRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerPasswordResetRequest$Response(params: {
    context?: HttpContext
    body: JofiPasswordResetRequestDto
  }
): Observable<StrictHttpResponse<JofiSuccessDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAuthenticationService.AuthenticationControllerPasswordResetRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiSuccessDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerPasswordResetRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerPasswordResetRequest(params: {
    context?: HttpContext
    body: JofiPasswordResetRequestDto
  }
): Observable<JofiSuccessDto> {

    return this.authenticationControllerPasswordResetRequest$Response(params).pipe(
      map((r: StrictHttpResponse<JofiSuccessDto>) => r.body as JofiSuccessDto)
    );
  }

  /**
   * Path part for operation authenticationControllerPasswordReset
   */
  static readonly AuthenticationControllerPasswordResetPath = '/v1/authentication/password-reset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerPasswordReset()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerPasswordReset$Response(params: {
    context?: HttpContext
    body: JofiPasswordResetDto
  }
): Observable<StrictHttpResponse<JofiTokenResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAuthenticationService.AuthenticationControllerPasswordResetPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerPasswordReset$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerPasswordReset(params: {
    context?: HttpContext
    body: JofiPasswordResetDto
  }
): Observable<JofiTokenResponseDto> {

    return this.authenticationControllerPasswordReset$Response(params).pipe(
      map((r: StrictHttpResponse<JofiTokenResponseDto>) => r.body as JofiTokenResponseDto)
    );
  }

  /**
   * Path part for operation authenticationControllerRefreshToken
   */
  static readonly AuthenticationControllerRefreshTokenPath = '/v1/authentication/refreshTokens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerRefreshToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRefreshToken$Response(params: {
    context?: HttpContext
    body: JofiRefreshtokenDto
  }
): Observable<StrictHttpResponse<JofiTokenResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAuthenticationService.AuthenticationControllerRefreshTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerRefreshToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRefreshToken(params: {
    context?: HttpContext
    body: JofiRefreshtokenDto
  }
): Observable<JofiTokenResponseDto> {

    return this.authenticationControllerRefreshToken$Response(params).pipe(
      map((r: StrictHttpResponse<JofiTokenResponseDto>) => r.body as JofiTokenResponseDto)
    );
  }

  /**
   * Path part for operation authenticationControllerLogout
   */
  static readonly AuthenticationControllerLogoutPath = '/v1/authentication/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogout$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAuthenticationService.AuthenticationControllerLogoutPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogout(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.authenticationControllerLogout$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authenticationControllerProfile
   */
  static readonly AuthenticationControllerProfilePath = '/v1/authentication/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerProfile$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JofiProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAuthenticationService.AuthenticationControllerProfilePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerProfile(params?: {
    context?: HttpContext
  }
): Observable<JofiProfileDto> {

    return this.authenticationControllerProfile$Response(params).pipe(
      map((r: StrictHttpResponse<JofiProfileDto>) => r.body as JofiProfileDto)
    );
  }

  /**
   * Path part for operation authenticationControllerDeleteUserProfile
   */
  static readonly AuthenticationControllerDeleteUserProfilePath = '/v1/authentication/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerDeleteUserProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerDeleteUserProfile$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAuthenticationService.AuthenticationControllerDeleteUserProfilePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerDeleteUserProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerDeleteUserProfile(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.authenticationControllerDeleteUserProfile$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authenticationControllerUpdateUserProfile
   */
  static readonly AuthenticationControllerUpdateUserProfilePath = '/v1/authentication/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerUpdateUserProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerUpdateUserProfile$Response(params: {
    context?: HttpContext
    body: JofiUpdateUserDto
  }
): Observable<StrictHttpResponse<JofiProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, JofiAuthenticationService.AuthenticationControllerUpdateUserProfilePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerUpdateUserProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerUpdateUserProfile(params: {
    context?: HttpContext
    body: JofiUpdateUserDto
  }
): Observable<JofiProfileDto> {

    return this.authenticationControllerUpdateUserProfile$Response(params).pipe(
      map((r: StrictHttpResponse<JofiProfileDto>) => r.body as JofiProfileDto)
    );
  }

}
