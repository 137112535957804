/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JofiCreateUploadDto } from '../models/jofi-create-upload-dto';
import { JofiUpload } from '../models/jofi-upload';

@Injectable({
  providedIn: 'root',
})
export class JofiContentManagementUploadsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uploadsControllerCreate
   */
  static readonly UploadsControllerCreatePath = '/v1/content-management/uploads';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadsControllerCreate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadsControllerCreate$Response(params: {
    context?: HttpContext
    body: JofiCreateUploadDto
  }
): Observable<StrictHttpResponse<JofiUpload>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementUploadsService.UploadsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JofiUpload>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadsControllerCreate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadsControllerCreate(params: {
    context?: HttpContext
    body: JofiCreateUploadDto
  }
): Observable<JofiUpload> {

    return this.uploadsControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<JofiUpload>) => r.body as JofiUpload)
    );
  }

  /**
   * Path part for operation uploadsControllerDownload
   */
  static readonly UploadsControllerDownloadPath = '/v1/content-management/uploads/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadsControllerDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerDownload$Response(params: {
    id: string;
    userId?: string;
    companyId?: string;
    apprenticeshipRequestId?: string;
    filename?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementUploadsService.UploadsControllerDownloadPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('userId', params.userId, {});
      rb.query('companyId', params.companyId, {});
      rb.query('apprenticeshipRequestId', params.apprenticeshipRequestId, {});
      rb.query('filename', params.filename, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadsControllerDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerDownload(params: {
    id: string;
    userId?: string;
    companyId?: string;
    apprenticeshipRequestId?: string;
    filename?: any;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.uploadsControllerDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation uploadsControllerRemove
   */
  static readonly UploadsControllerRemovePath = '/v1/content-management/uploads/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadsControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerRemove$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementUploadsService.UploadsControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadsControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerRemove(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.uploadsControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation uploadsControllerRemoveForCompany
   */
  static readonly UploadsControllerRemoveForCompanyPath = '/v1/content-management/uploads/{companyId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadsControllerRemoveForCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerRemoveForCompany$Response(params: {
    id: string;
    companyId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementUploadsService.UploadsControllerRemoveForCompanyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadsControllerRemoveForCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerRemoveForCompany(params: {
    id: string;
    companyId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.uploadsControllerRemoveForCompany$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation uploadsControllerDownloadPublic
   */
  static readonly UploadsControllerDownloadPublicPath = '/v1/content-management/uploads/public/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadsControllerDownloadPublic()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerDownloadPublic$Response(params: {
    id: string;
    companyId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementUploadsService.UploadsControllerDownloadPublicPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('companyId', params.companyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadsControllerDownloadPublic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerDownloadPublic(params: {
    id: string;
    companyId?: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.uploadsControllerDownloadPublic$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation uploadsControllerDownloadFile
   */
  static readonly UploadsControllerDownloadFilePath = '/v1/content-management/uploads/{id}/{filename}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadsControllerDownloadFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerDownloadFile$Response(params: {
    id: string;
    filename: string;
    userId?: string;
    companyId?: string;
    apprenticeshipRequestId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, JofiContentManagementUploadsService.UploadsControllerDownloadFilePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('filename', params.filename, {});
      rb.query('userId', params.userId, {});
      rb.query('companyId', params.companyId, {});
      rb.query('apprenticeshipRequestId', params.apprenticeshipRequestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadsControllerDownloadFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadsControllerDownloadFile(params: {
    id: string;
    filename: string;
    userId?: string;
    companyId?: string;
    apprenticeshipRequestId?: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.uploadsControllerDownloadFile$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
