import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import '@angular/common/locales/global/de-CH';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as moment from 'moment';
import { AppInitializer } from './app.initializer';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DateToAgePipe } from './pipes/date-to-age.pipe';
import { BooleanToTextKeyPipe } from './pipes/boolean-to-text.pipe';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { ApiModule } from './_generated/jofi-rest-api';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AppState, STATE_NAME } from './state/app/app.state';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { IfIsAdminGuard } from './guards/if-is-admin.guard';
import { IfLoggedInGuard } from './guards/if-logged-in.guard';
import { RedirectIfLoggedInGuard } from './guards/redirect-if-logged-in.guard';
import { DATE_PIPE_DEFAULT_TIMEZONE, DatePipe } from '@angular/common';
import { ApiTokenInterceptor } from '../_common/api-token.interceptor';
import { ApiJsonInterceptor } from '../_common/api-json.interceptor';
import { ApiErrorInterceptor } from '../_common/api-error.interceptor';
import { LoginComponent } from './components/login/login.component';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { LoginPage } from './pages/login/login.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { UsersPage } from './pages/users/users.page';
import { CompaniesPage } from './pages/companies/companies.page';
import { PaginationComponent } from './components/pagination/pagination.component';
import { CompaniesDetailPage } from './pages/companies-detail/companies-detail.page';
import { UsersDetailPage } from './pages/users-detail/users-detail.page';
import { TodosPage } from './pages/todos/todos.page';
import { InterestsPage } from './pages/interests/interests.page';
import { NgxEditorModule } from 'ngx-editor';
import { FilterPipe } from './pipes/filter.pipe';
import { ProfessionsPage } from './pages/professions/professions.page';
import { ProfessionsNewPage } from './pages/professions-new/professions-new.page';
import { PartnersPage } from './pages/partners/partners.page';
import { PartnersNewPage } from './pages/partners-new/partners-new.page';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { UploadComponent } from './components/upload/upload.component';
import { SecuredImageComponent } from './components/secured-image/secured-image.component';
import { InterestsNewPage } from './pages/interests-new/interests-new.page';
import { StudentProfilesPublicPage } from './pages/student-profiles-public/student-profiles-public.page';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(appInitializer: AppInitializer) {
  return (): Promise<any> => {
    return appInitializer.initialize();
  };
}

const timezoneFactory = () => {
  const isSommerzeit = moment().isDST();
  return isSommerzeit ? 'CEST' : 'CET';
};

@NgModule({
  declarations: [
    AppComponent,
    BooleanToTextKeyPipe,
    CompaniesPage,
    CompaniesDetailPage,
    DateToAgePipe,
    DashboardPage,
    InterestsPage,
    LoginComponent,
    LoginPage,
    NotFoundPage,
    PaginationComponent,
    TodosPage,
    TruncateTextPipe,
    UsersPage,
    UsersDetailPage,
    FilterPipe,
    PartnersPage,
    PartnersNewPage,
    ProfessionsPage,
    ProfessionsNewPage,
    UploadComponent,
    SecuredImageComponent,
    InterestsNewPage,
    StudentProfilesPublicPage,
  ],
  imports: [
    ApiModule.forRoot({ rootUrl: environment.apiConfiguration.baseUrl }),
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    NgxsModule.forRoot([
      AppState,
    ], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.LocalStorage,
      key: [STATE_NAME],
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.isLoggingEnabled.ngxs,
    }),
    NgxsResetPluginModule.forRoot(),
    SweetAlert2Module.forRoot(),
    NgxEditorModule,
    NgxDropzoneModule,
  ],
  providers: [
    AppInitializer,
    IfIsAdminGuard,
    IfLoggedInGuard,
    RedirectIfLoggedInGuard,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializer],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiJsonInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-CH',
    },
    {
      provide: DATE_PIPE_DEFAULT_TIMEZONE,
      useFactory: timezoneFactory,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'CHF',
    },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule {
}
