<nav class="jofi-pagination" aria-label="Page navigation" *ngIf="totalPages && totalPages >= 2 && currentPage">
  <ul class="inline-flex -space-x-px">
    <li>
      <button (click)="goToPreviousPage()"
              [disabled]="currentPage === 1"
              [class.opacity-50]="currentPage === 1"
              class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700">
        Zurück
      </button>
    </li>
    <ng-container *ngFor="let page of pages">
      <li>
        <button (click)="goToPage(page)"
                aria-current="page"
                [ngClass]="currentPage === page ? 'text-blue-600 bg-blue-50' : 'text-gray-500'"
                class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700">
          {{ page }}
        </button>
      </li>
    </ng-container>
    <li>
      <button (click)="goToNextPage()"
              [disabled]="currentPage >= totalPages"
              [class.opacity-50]="currentPage >= totalPages"
              class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700">
        Weiter
      </button>
    </li>
  </ul>
</nav>
