import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { throwError } from 'rxjs';
import { LoggerService } from './logger/logger.service';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf(environment.apiConfiguration.baseUrl) > -1) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = `Fehler: ${error.error.message}`;
          LoggerService.ERROR(this, 'errorMsg', errorMsg);
          if (error.error.message && error.status !== 401) {
            return throwError(error.error.message);
          }
          return throwError(error);
        }),
      );
    }
    return next.handle(req);
  }
}
