import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '../../state/app/app.state';
import { JofiAdminCompaniesService, JofiCompany } from '../../_generated/jofi-rest-api';
import { Paginated } from 'nestjs-paginate';

@Component({
  selector: 'jofi-companies-page',
  templateUrl: './companies.page.html',
  styleUrls: ['companies.page.scss'],
})
export class CompaniesPage implements OnDestroy {
  public user$ = this.store.select(AppState.user);
  public entitiesPaginated: Paginated<JofiCompany> | undefined;
  public searchKeyWord: string = '';

  constructor(
    private readonly store: Store,
    private readonly jofiAdminCompaniesService: JofiAdminCompaniesService,
  ) {
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy(): void {
  }

  public search() {
    if (this.searchKeyWord) {
      this.load(1, this.searchKeyWord);
    } else {
      this.reset();
    }
  }

  public reset() {
    this.searchKeyWord = '';
    this.load();
  }

  public goToPage(page: number) {
    this.load(page);
  }

  private load(pageToLoad?: number, search: string = '') {
    if (!pageToLoad) {
      pageToLoad = 1;
    }
    this.jofiAdminCompaniesService.companiesAdminControllerFindAllPaginated({
      page: pageToLoad,
      search: search,
    }).subscribe({
      next: (companies: any) => {
        this.entitiesPaginated = companies;
      },
    });
  }
}
