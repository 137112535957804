<div>
  <h1 class="text-2xl mb-4 flex items-center gap-4">
    Lehrberufe
    <a routerLink="new"
       class="w-8 h-8 text-white bg-blue-700 hover:bg-blue-800 rounded-full">
      <svg class="text-white" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg"
           aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
      </svg>
    </a>
  </h1>

  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" class="px-6 py-3">
          Name
        </th>
        <th scope="col" class="px-6 py-3">
          Beschreibung
        </th>
        <th scope="col" class="px-6 py-3">
          Aktionen
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="bg-white border-b hover:bg-gray-50" *ngFor="let profession of professions">
        <td class="px-6 py-4">
          <input type="text" name="name"
                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                 [(ngModel)]="profession.name">
        </td>
        <td class="px-6 py-4">
          <textarea name="description"
                    rows="5"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    [(ngModel)]="profession.description"></textarea>
        </td>
        <td class="px-6 py-4">
          <button type="submit"
                  (click)="update(profession)"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            {{ 'global.ui.save' | translate }}
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
