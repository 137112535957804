<div>
  <h1 class="text-2xl mb-4">
    Willkommen auf dem Dashboard, {{ (user$ | async)?.firstName }}.
  </h1>
  <h2 class="text-xl font-bold mb-2">Registrationen</h2>
  <div class="grid gap-4 mb-6 md:grid-cols-3" *ngIf="registrationStats">
    <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow">
      Unternehmen
      <p class="font-bold text-xl">{{ registrationStats.companies }}</p>
    </div>
    <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow">
      Recruiters
      <p class="font-bold text-xl">{{ registrationStats.recruiters }}</p>
    </div>
    <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow">
      Schüler:innen
      <p class="font-bold text-xl">{{ registrationStats.students }}</p>
      <p><small>Davon haben <strong>{{ getPercentage(registrationStats.studentsWithProfilePrivate, registrationStats.students) }}</strong> ihre persönlichen Daten
        abgefüllt, <strong>{{ getPercentage(registrationStats.studentsWithProfilePublic, registrationStats.students) }}</strong> Schule & Co. ausgefüllt
        und <strong>{{ getPercentage(registrationStats.studentsWithInterests, registrationStats.students) }}</strong> ihre Interessen hinterlegt.</small></p>
    </div>
  </div>

  <h2 class="text-xl font-bold mb-2">Lehrstellen & Anfragen</h2>
  <div class="grid gap-4 mb-6 md:grid-cols-3" *ngIf="apprenticeshipStats">
    <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow">
      Erstelle Lehrstellen
      <p class="font-bold text-xl">{{ apprenticeshipStats.apprenticeships }}</p>
    </div>
    <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow">
      Anfragen von Firmen zu Schüler:innen
      <p class="font-bold text-xl">{{ apprenticeshipStats.apprenticeshipRequests }}</p>
      <p><small>Davon <strong>{{ apprenticeshipStats.apprenticeshipRequestsAccepted }}</strong>
        akzeptiert, <strong>{{ apprenticeshipStats.apprenticeshipRequestsDeclined }}</strong>
        abgelehnt, <strong>{{ apprenticeshipStats.apprenticeshipRequestsOpen }}</strong> nicht beantwortet
        und <strong>{{ apprenticeshipStats.apprenticeshipRequestsWithdrawn }}</strong> zurückgezogen.</small></p>
    </div>
  </div>

  <h2 class="text-xl font-bold mb-2">Schüler:innen</h2>
  <div class="grid gap-4 mb-6" *ngIf="allStudents && allStudents.length">
    <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow">
      <p>Total registrierte Schüler:innen: {{ allStudents.length }}</p>
      <p>E-Mail bestätigt: {{ (allStudents | filter : filterConfirmed).length }}</p>
      <p>8. Klasse: {{ (allStudents | filter : filter8Klasse).length }}</p>
      <p>9. Klasse: {{ (allStudents | filter : filter9Klasse).length }}</p>
      <p>10. Klasse: {{ (allStudents | filter : filter10Klasse).length }}</p>
      <p>Baselland: {{ (allStudents | filter : filterCantonBL).length }}</p>
      <p>Baselstadt: {{ (allStudents | filter : filterCantonBs).length }}</p>
      <p>Aargau: {{ (allStudents | filter : filterCantonAg).length }}</p>
    </div>
  </div>
</div>
